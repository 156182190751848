import React from "react";
import "./styles.scss";

export default function ResultsComponent({ results }) {

  return (
    <div className="results-component-wrapper">
      {results?.map((result, index) => {
        return (
          <div className="card-wrapper" key={`result-${index}-${result?._id}`}>
            <h2>{result?.title}</h2>
            {!result?._resultsFiles?.length ?
              <div className='empty-state small' key={`empty-state-${index}-${result?._id}`}>
                <div className='image-wrapper'>
                  <img src={require('../../assets/images/results-empty-state.png')} alt='empty state mountain image' />
                </div>
                <p>No results added yet</p>
              </div> :
              <div className="results-wrapper blue">
    
                {result?._resultsFiles?.map((resultFile, i) => {
                  return (
                    <div 
                    className="result-wrapper"
                    key={`file-${i}-${result?._id}`}
                    onClick={() => window.open(resultFile?.url)}>
                      <div className="image-wrapper flex">
                        <img src={require("../../assets/images/link-blue-bgr.svg").default} />
                      </div>
                      <div className="name-wrapper flex">
                        <p>{resultFile?.title || 'file name'}</p>
                      </div>
                    </div>
                  )
                })}
              </div>}
          </div>
        )
      })}

    </div>
  );
}
