import React from "react";
import "./styles.scss";
import { useState } from "react";

export default function Hotels({ hotelsRef }) {
  const [selected, setSelected] = useState("1");
  const [selectedOption, setSelectedOption] = useState("About");
  const options = [ "About", "Teams" ];
  const [hotelData, setHotelData] = useState([
    {
      name: "Hotel Kempinski grand arena 5*",
      img: require("../../assets/images/Kempinski.jpg"),
      link: "https://www.kempinski.com/en/hotel-grand-arena",
      text: [
        "Kempinski Hotel Grand Arena Bansko - the most awarded, 5-star luxury ski and spa hotel in Bansko and Bulgaria.",
        "Being the only ski-in and ski-out hotel in the area, Kempinski Hotel Grand Arena Bansko is located right across from the Gondola lift station, which gives access to 75 km of prime-quality ski runs.",
        "The hotel features 157 Alpine-style rooms, including 25 suites and an exclusive Presidential Suite, state-of-the-art spa facilities, four conference rooms, as well as five bars and three restaurants - Sushi & Teppanyaki, Come Prima Mediterranean fine dining and a family restaurant with live entertainment. Graced with Kempinski European flair and traditions, the hotel grants the ultimate ski, gourmet, and spa experience."
      ],
      teams: [
        "Belgrade Ski Team",
        "KontrolerSki Team Poland",
        "Milano Radar Team",
        "Moosetricht GFL Team",
        "Portugal Snowteam",
        "RomaniaX",
        "Ski Team Control Australia",
        "Team USA"
      ],
      order: "1",
    },
    {
      name: "Regnum Bansko 5*",
      img: require("../../assets/images/Regnum.jpg"),
      link: "https://regnum.bg/",
      text: [
        "Here you will find out a combination of spacious Suites with spectacular views of Pirin Mountain, the only aqua park in Bansko, perfect facilities for conference events, team buildings, weddings, children’s birthday parties. There is in Regnum the largest conference hall in Bansko - 400 seats theater arrangement."
      ],
      teams: [
        "GFL Team Switzerland",
      ],
      order: "2",
    },
    {
      name: "Premier Luxury Mountain Resort 5*",
      img: require("../../assets/images/Premier.jpeg"),
      link: "https://www.banskohotelpremier.com/",
      text: [
        "You are looking at one of the finest Bansko hotels! Get ready for a magical trip to a world of hospitality enriched with activities suitable for nature lovers, families, and sport aficionados.",
        "Mount Pirin’s natural beauty, combined with Premier Luxury Mountain Resort’s impeccable facilities and welcoming ambiance, results in a retreat that elevates your vacation in Bansko to a higher level of indulgence! Travelers can also experience Pirin National Park, the famous Unesco World Heritage site, which is just a breath away from this Bansko hotel."
      ],
      teams: [
        "Team España",
      ],
      order: "3",
    },
    {
      name: "Four Points by Sheraton Bansko 4*",
      img: require("../../assets/images/Four-Points-hotel.jpg"),
      link: "https://strazhitehotel.eu/",
      text: [
        "Stay in a much-loved destination in Bulgaria! Our cosy and well equipped hotel has a great location, just a few steps frоm the Gondola Station and the pedestrian zone.",
        "● Will bring you unforgettable vacation experience",
        "● Offers amazing panoramic views over the Pirin Mountain.",
        "● Suitable for families and ski & snowboard lovers"
      ],
      teams: [
        "Bologna Ski Team",
        "Bremen FishHeadz",
        "Mainhattan Ski Team",
        "Munich Snowteam",
        "Padova Radar Winter Team",
        "Rhein Radar Farting Huskies",
        "Ski Team Austria",
        "SnowTeam Stuttgart",
        "Team Canada 'The Flying Beavers'",
        "Team España",
        "Team Finland",
        "Team Hamburg",
        "Team Prague",
        "Team UK",
        "Tower Falcons Germany"
      ],
      order: "4",
    },
    {
      name: "Platinum Hotel & Casino Bansko 4*",
      img: require("../../assets/images/Platinum.jpg"),
      link: "https://bansko.platinum.bg/",
      text: [
        "The hotel rooms and suites at Platinum hotel & casino offer an amazing blend of beauty and elegance. Our rooms will elevate your stay, whether you're traveling for celebrating a honeymoon, business or ski vacation.",
        "In Platinum Hotel & Casino, Bansko there are bars & dines for each standard and preferences. From signature dining to casual, our amazing collection of some of the best Bansko restaurants offers something for every taste and occasion.",
        "At the Platinum SPA and Relax Zone, you can relax your body and refresh your senses. From the moment you enter our spa, your every wish is anticipated, so there is never a need to ask."
      ],
      teams: [
        "Air Chance",
        "AIXTRA Team",
        "Hungarian SnowBulls",
        "KontrolerSki Team Poland",
        "Lyon Queens",
        "Paris GFL Team",
        "ROMAnes Ski Team",
        "Snowdogs",
        "Team Bordeaux",
        "Team Iceland"
      ],
      order: "5",
    },
    {
      name: "St. George Ski & Holidays Hotel 4* / Aparthotel",
      img: require("../../assets/images/St.George.jpeg"),
      link: "https://stgeorgespabansko.com/en/",
      text: [
        "St. George Ski and Holiday is a fabulous 4* star hotel of luxury rooms, studios and modern apartments."
      ],
      teams: [
        "Belgrade Ski Team",
        "Pink Team Reims",
        "Team Belgium",
        "Team Ireland"
      ],
      order: "6",
    },
    {
      name: "Hotel Parklands Aparthotel",
      img: require("../../assets/images/Parklands.jpg"),
      link: "https://www.parklands.eu/",
      text: [
        "Parklands Hotel is located at the foot of Pirin Mountain, only 500m. from the gondola lift.",
        "An indoor heated swimming pool with mineral water, measuring 20m by 8m and temperature 30 degrees, is available to guests. Jacuzzi with massaging effect. Gym with modern equipment. Two saunas and steam bath, contrast shower with ice bucket. Massage room with wonderful massage therapists. At Hotel Parklands, you can rent ski equipment, snowboards, children's sledges, as well as receive a ski lift card upon prior request.",
        "Guests can use free Wi-Fi in all rooms and the services of the 24-hour reception."
      ],
      teams: [
        "GFL Team Norway",
        "sLOVEnia GFL Team",
        "Team Pacific",
        "Team Prague",
        "Team Rudi",
        "Team S-Tonic",
        "Team Sweden",
        "TeАmsterdam"
      ],
      order: "7",
    },
  ]);


  // const [hotelData, setHotelData] = useState([
  //   {
  //     name: "Hotel Kempinski grand arena 5*",
  //     img: require("../../assets/images/hotel-1.png"),
  //     link: "https://www.kempinski.com/en/hotel-grand-arena",
  //     order: "1",
  //   },
  //   {
  //     name: "5* accommodation",
  //     img: require("../../assets/images/hotel-2.png"),
  //     link: "",
  //     order: "2",
  //   },
  //   {
  //     name: "4* accommodation",
  //     img: require("../../assets/images/hotel-2.png"),
  //     link: "",
  //     order: "3",
  //   },
  //   {
  //     name: "Aparthotel accommodation",
  //     img: require("../../assets/images/hotel-2.png"),
  //     link: "",
  //     order: "4",
  //   },
  // ]);

  return (
    <div className="hotels-section-wrapper" ref={hotelsRef}>
      <h1>HOTELS & TEAMS</h1>
      <div className="flex">
        <div className="hotels-wrapper">
          <div className="photos-wrapper desktop">
            {hotelData.map((item, i) => {
              return (
                <div
                  className={`hotel ${item.order === selected ? "selected" : ""} ${item?.link ? "link" : ""}`}
                  key={`photo-${i + 14}-${i * 2}`}
                  onClick={() => window.open(item.link)}
                >
                  <img src={item.img} />
                  <h2>{item.name}</h2>
                </div>
              );
            })}
          </div>

          <div className="options-wrapper">
            {hotelData.map((el, i) => (
              <div
                className={`option ${el.order === selected ? "selected" : ""}`}
                onClick={() => setSelected(el.order)}
                key={`hotel-${i + 14}-${i * 2}`}
              ></div>
            ))}
          </div>
        </div>

        <div className="data-hotels-wrapper">
          <div className="options-wrapper">
          {options?.map((el, index) => {
            return (
              <div
              className={`option ${selectedOption === el ? "selected" : ""} ${index === 0 ? 'first' : ''}`}
              onClick={() => setSelectedOption(el)}
            >
              {el}
            </div>
            )
          })}
          </div>
          <div className={`text-hotels-wrapper desktop ${selectedOption === "Teams" ? 'flex' : ''}`}>
            {selectedOption === "About" ?
            hotelData.find(hotel => hotel.order === selected)?.text.map((el, i) => (
              <p>{el}</p>
            )) :
            <>
            <div className="col left">
            {hotelData.find(hotel => hotel.order === selected)?.teams?.slice(0, 8).map((el, i) => (
              <div className="row-wrapper">
                <div className="line" />
                <p>{el}</p>
              </div>
            ))}
            </div>

            {hotelData.find(hotel => hotel.order === selected)?.teams?.length > 8 && 
            <div className="col right">
              {hotelData.find(hotel => hotel.order === selected)?.teams?.slice(8).map((el, i) => (
              <div className="row-wrapper">
                <div className="line" />
                <p>{el}</p>
              </div>
            ))}
            </div>}
            </>}
          </div>

          <div className='photos-wrapper text-hotels-wrapper mobile'>
          {hotelData.map((item, i) => {
              return (
                <div className="mobile-hotel-wrapper">
                  <div className="text-hotels-wrapper col">
                    {item?.text?.map(el => <p>{el}</p>)}
                  </div>

                  <div
                    className={`hotel ${item.order === selected ? "selected" : ""} ${item?.link ? "link" : ""}`}
                    key={`photo-${i + 14}-${i * 2}`}
                    onClick={() => window.open(item.link)}
                  >
                    <img src={item.img} />
                    <h2>{item.name}</h2>
                  </div>

                  <div className="text-hotels-wrapper sections">
                    <div className="section left">
                    {item?.teams?.slice(0, 8)?.map(el => (
                      <div className="row-wrapper">
                        <div className="line" />
                        <p>{el}</p>
                      </div>
                    ))}
                    </div>
                    
                    <div className="section right">
                    {item?.teams?.length > 8 &&
                     item?.teams?.slice(8)?.map(el => (
                      <div className="row-wrapper">
                        <div className="line" />
                        <p>{el}</p>
                    </div>))}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

      </div>
    </div>
  );
}
