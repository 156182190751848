import React from "react";
import "./styles.scss";
import { useState, useEffect } from "react";
import { PricingPopup } from "../PricingPopup";
import { PricingFeesPopup } from "../PricingFeesPopup";
import { calculatePaymentPerPerson } from "../../config/utilities";
import Sheet from 'react-modal-sheet';

export default function Pricing({ pricingRef }) {
    const [ gflData, setGflData ] = useState([
        {hotel: 'Aparthotel accommodation', rooms: [ {type: 'For max 3 persons', price: '900 EUR per person'}, {type: 'For max 4 persons', price: '880 EUR per person'}]},
        {hotel: '4* accommodation', rooms: [ {type: 'Single room', price: '1390 EUR per person'}, {type: 'Double room', price: '1100 EUR per person'}]},
        {hotel: '5* accommodation', rooms: [ {type: 'Single room', price: '2150 EUR per person'}, {type: 'Double room', price: '1450 EUR per person'}]},
        {hotel: 'Aparthotel accommodation', rooms: [ {type: 'Single room', price: '2350 EUR per person'}, {type: 'Double room', price: '1650 EUR per person'}]}
    ]);

    const popupData = [
      { light: 'Free cancellation (excluding OC fee): ', bold: 'up to 60 days prior to arrival'},
      { light: 'Cancellations made between 59 days and 30 days prior to arrival: ', bold: '50% + OC fee cancellation fee'},
      { light: 'Cancellations made between 29 days and 0 days prior to arrival: ', bold: '100% + OC fee cancellation fee'},
      { light: 'N.B.: ', bold: 'OC is non refundable at any time.'},
    ]

    const [ width, setWidth ] = useState(0);
    const [ open, setOpen ] = useState(false);
    const [ feesPopup, setFeesPopup ] = useState(false);
    const [ mobilePopupHotelData, setMobilePopupHotelData ] = useState({ 
      hotel: '', roomType: '', pricePerPerson: 0, firstPayment: 0, secondPayment: 0 });

    let isMobile = width < 1300;

    const handleClick = (hotel, roomType, pricePerPerson, firstPayment, secondPayment) => {
      if (hotel) {
        setOpen(true)
        if (hotel && roomType && pricePerPerson && firstPayment && secondPayment) {
          setMobilePopupHotelData({ hotel, roomType, pricePerPerson, firstPayment, secondPayment })
        }
        else {
          setMobilePopupHotelData({ hotel: '', roomType: '', pricePerPerson: 0, firstPayment: 0, secondPayment: 0 })
        }
      }

      else if (!hotel) {
        setOpen(true)
        setFeesPopup(true)
      }
    }

    useEffect(() => {
      window.addEventListener('resize', setWidth(window.innerWidth));
      isMobile = window.innerWidth < 1300
  
      return () => window.removeEventListener('resize', setWidth(window.innerWidth));
    }, [window.innerWidth]);

  return (
    <div className="pricing-section-wrapper" ref={pricingRef}>
      <h1>Pricing</h1>

      <div className="content-wrapper">
        <div className="img-wrapper">
          <img src={require("../../assets/images/pricing-photo.png")} />
        </div>

        <div className="info-wrapper">

            {/* <div className="table-wrapper pricing">
                <div className="table-header">2023 - PRE-GFL</div>
                <div className="table-row col">
                   <div className="row-text">Two nights 4* hotel including breakfast and dinner</div>

                   <div className="table-row no-shadow no-margin">
                      <div className="row-text light">Single room</div>
                      <div className="row-text blue">165 EUR per person</div>
                   </div>
                   <div className="table-row no-shadow no-margin">
                      <div className="row-text light">Double room</div>
                      <div className="row-text blue">130 EUR per person</div>
                   </div>
               </div>
            </div> */}

            <div className="table-wrapper pricing">
               <div className="table-header">2024 - GFL</div>

               {/* {gflData?.map(hotel => {
                return (
                   <div className="table-row">
                       <div className="row-text">{hotel.rooms.type}</div>
                       <div className="row-text blue">{hotel.rooms.price}</div>
                   </div>
                )
               })} */}

               <div className="table-row col">
                   <div className="row-text">Aparthotel accommodation</div>

                   <div className="table-row no-shadow no-margin">
                      <div className="row-text light">For max 3 persons</div>
                      <div className="row-text blue">900 EUR per person
                      <span>
                        <img className='info-icon' src={require("../../assets/images/info-icon.svg").default} onClick={() => handleClick('Aparthotel accommodation', 'For max 3 persons', 900, calculatePaymentPerPerson(900, 'firstPayment'), calculatePaymentPerPerson(900, 'secondPayment'))} />
                        {/* <PricingPopup firstPayment='560' secondPayment='540' /> */}
                        <PricingPopup firstPayment={calculatePaymentPerPerson(900, 'firstPayment')} secondPayment={calculatePaymentPerPerson(900, 'secondPayment')} />
                      </span>
                      </div>
                   </div>
                   <div className="table-row no-shadow no-margin">
                      <div className="row-text light">For max 4 persons</div>
                      <div className="row-text blue">880 EUR per person
                      <span>
                        <img className='info-icon' src={require("../../assets/images/info-icon.svg").default} onClick={() => handleClick('Aparthotel accommodation', 'For max 4 persons', 880, calculatePaymentPerPerson(880, 'firstPayment'), calculatePaymentPerPerson(880, 'secondPayment'))} />
                        <PricingPopup firstPayment={calculatePaymentPerPerson(880, 'firstPayment')} secondPayment={calculatePaymentPerPerson(880, 'secondPayment')} />
                      </span>
                      </div>
                   </div>
               </div>

               <div className="table-row col">
                   <div className="row-text">4* accommodation</div>

                   <div className="table-row no-shadow no-margin">
                      <div className="row-text light">Single room</div>
                      <div className="row-text blue">1390 EUR per person
                      <span>
                        <img className='info-icon' src={require("../../assets/images/info-icon.svg").default} onClick={() => handleClick('4* accommodation', 'Single room', 1390, calculatePaymentPerPerson(1390, 'firstPayment'), calculatePaymentPerPerson(1390, 'secondPayment'))} />
                        <PricingPopup firstPayment={calculatePaymentPerPerson(1390, 'firstPayment')} secondPayment={calculatePaymentPerPerson(1390, 'secondPayment')} />
                      </span>
                      </div>
                   </div>
                   <div className="table-row no-shadow no-margin">
                      <div className="row-text light">Double room</div>
                      <div className="row-text blue">1100 EUR per person
                      <span>
                        <img className='info-icon' src={require("../../assets/images/info-icon.svg").default} onClick={() => handleClick('4* accommodation', 'Double room', 1100, calculatePaymentPerPerson(1100, 'firstPayment'), calculatePaymentPerPerson(1100, 'secondPayment'))} />
                        <PricingPopup firstPayment={calculatePaymentPerPerson(1100, 'firstPayment')} secondPayment={calculatePaymentPerPerson(1100, 'secondPayment')} />
                      </span>
                      </div>
                   </div>
               </div>

               <div className="table-row col">
                   <div className="row-text">5* accommodation</div>

                   <div className="table-row no-shadow no-margin">
                      <div className="row-text light">Single room</div>
                      <div className="row-text blue">2150 EUR per person
                      <span>
                        <img className='info-icon' src={require("../../assets/images/info-icon.svg").default} onClick={() => handleClick('5* accommodation', 'Single room', 2150, calculatePaymentPerPerson(2150, 'firstPayment'), calculatePaymentPerPerson(2150, 'secondPayment'))} />
                        <PricingPopup firstPayment={calculatePaymentPerPerson(2150, 'firstPayment')} secondPayment={calculatePaymentPerPerson(2150, 'secondPayment')} />
                      </span>
                      </div>
                   </div>
                   <div className="table-row no-shadow no-margin">
                      <div className="row-text light">Double room</div>
                      <div className="row-text blue">1450 EUR per person
                      <span>
                        <img className='info-icon' src={require("../../assets/images/info-icon.svg").default} onClick={() => handleClick('5* accommodation', 'Double room', 1450, calculatePaymentPerPerson(1450, 'firstPayment'), calculatePaymentPerPerson(1450, 'secondPayment'))} />
                        <PricingPopup firstPayment={calculatePaymentPerPerson(1450, 'firstPayment')} secondPayment={calculatePaymentPerPerson(1450, 'secondPayment')} />
                      </span>
                      </div>
                   </div>
               </div>

               <div className="table-row col">
                   <div className="row-text">5* accommodation Kempinski Hotel Grand Arena</div>

                   <div className="table-row no-shadow no-margin">
                      <div className="row-text light">Single room</div>
                      <div className="row-text blue">2350 EUR per person
                      <span>
                        <img className='info-icon' src={require("../../assets/images/info-icon.svg").default} onClick={() => handleClick('5* accommodation Kempinski Hotel Grand Arena', 'Single room', 2350, calculatePaymentPerPerson(2350, 'firstPayment'), calculatePaymentPerPerson(2350, 'secondPayment'))} />
                        <PricingPopup firstPayment={calculatePaymentPerPerson(2350, 'firstPayment')} secondPayment={calculatePaymentPerPerson(2350, 'secondPayment')} />
                      </span>
                      </div>
                   </div>
                   <div className="table-row no-shadow no-margin">
                      <div className="row-text light">Double room</div>
                      <div className="row-text blue">1650 EUR per person
                      <span>
                        <img className='info-icon' src={require("../../assets/images/info-icon.svg").default} onClick={() => handleClick('5* accommodation Kempinski Hotel Grand Arena', 'Double room', 1650, calculatePaymentPerPerson(1650, 'firstPayment'), calculatePaymentPerPerson(1650, 'secondPayment'))} />
                        <PricingPopup firstPayment={calculatePaymentPerPerson(1650, 'firstPayment')} secondPayment={calculatePaymentPerPerson(1650, 'secondPayment')} />
                      </span>
                      </div>
                   </div>
               </div>

               <div className="table-row blue">
                      <div className="row-text light">Аdditional OC fee (Non refundable)</div>
                      <div className="row-text blue">200 EUR per person</div>
               </div>
               <div className="table-row">
                  <div className="row-text light">Note:</div>
                  <div className="row-text bold">Cancelation fees apply depending on cancelation time
                      <span>
                        <img className='info-icon' src={require("../../assets/images/info-icon.svg").default} onClick={() => handleClick()} />
                        <PricingFeesPopup data={popupData} />
                      </span>
                  </div>
               </div>

            </div>
        </div>
      </div>

      <Sheet isOpen={open} onClose={() => {
        setOpen(false)
        setFeesPopup(false)
        }} snapPoints={[0.40]}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            {feesPopup ?
            <PricingFeesPopup isMobile={true} data={popupData} /> :
            <PricingPopup isMobile={true} data={mobilePopupHotelData} />}
          </Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop />
     </Sheet>
    </div>
  );
}
