import React from 'react';
import "./styles.scss";

export default function TableHeader({ tableHeaderElements }) {
  return (
    <div className='table-header-wrapper'>
        {tableHeaderElements?.map((el, i) => {
            return <div className={`element ${el?.size}`} key={`table-header-el-${el?.name}-${i}`}>{el?.name}</div>
        })}
    </div>
  )
}
