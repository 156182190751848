import React from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

export default function About({ aboutRef }) {
  const navigate = useNavigate();

  return (
    <div className="about-section-wrapper" ref={aboutRef}>
      <img src={require("../../assets/images/ski-about-section.svg").default} />

      <div className="text-section">
        <h1>About gfl ‘24</h1>
        <p>
          Golden Flight Level (GFL) is the annual international winter sports
          championship of air traffic controllers with participants from all
          across Europe, as well as from overseas (Canada, USA, Carribbean,
          Russia, Dubai and Australia).
        </p>

        <p>
          Today GFL includes skiing, snowboard and cross country races, as well
          as spectacular accompanying events, including an opening ceremony, a
          theme party and a prize giving ceremony with gala dinner at the end of
          the week.
        </p>

        <p>
          In 2024 the 49th edition of Golden Flight Level will take place in
          Bansko in Bulgaria from March 16th to March 23rd.
        </p>

        <div className="buttons-wrapper">
          <button onClick={() => navigate('/history')} >
            Learn more
          </button>

          <button className="secondary"
            onClick={() => { window.open('https://www.youtube.com/watch?v=wRuxHYAQqKM')}}>
            Watch video
          </button>
        </div>
      </div>
    </div>
  );
}
