import React from "react";
import "./styles.scss";
import { useState } from "react";

export default function Transportation({ transportationRef }) {
  const [options, setOptions] = useState([true, false, false]);
  const [selected, setSelected] = useState("1");

  const onToggle = (num) => {
    let newOptions = options.map((el, index) =>
      index !== num ? (el = false) : (el = true)
    );
    setOptions(newOptions);
  };

  return (
    <div className="transportation-section-wrapper" ref={transportationRef}>
      <h1>About Bansko</h1>

      <div className="mobile-wrapper">
        <div className="option" onClick={() => onToggle(0)}>
          Getting to bansko
        </div>

        <div className="transport-wrapper">
          <div className="transport-heading">By plane</div>
          <div className="text border">
            The most convenient airport is Sofia International /LBSF/. There
            will be organised, paid transport from there to Bansko for those who
            request it. Duration of the trip is approximately two hours.
          </div>
        </div>

        <div className="transport-wrapper">
          <div className="transport-heading">By car</div>
          <div className="text border">
            Bansko is located in the southwestern part of the country. It is
            easily accessible from all directions throughout the year. Keep in
            mind that a vignette is required for driving on the roads. More
            information on parking options to come at a later stage.
          </div>
        </div>

        <div className="transport-wrapper">
          <div className="transport-heading">By train and bus</div>
          <div className="text">
            Sofia Airport Metro Station is in close proximity to the airport
            terminal building. Changing trains from Line 1 to Line 2 at Serdika
            Metro Station, passengers can travel from the airport to Sofia
            Central Bus and Railway Stations within less than half an hour. For
            further information on the railway time schedule, see BDZ Passenger
            Services and for bus schedule see the Central Bus Station.
          </div>
          <div className="text">
            By bus from Sofia Central Bus Station the estimated travel time to
            Bansko Bus Station is approximately 3 hours.
          </div>
          <div className="text">
            By train from Sofia Central Railway Station the estimated travel
            time to Bansko Railway Station is approximately 6 hours.
          </div>

          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11890.622089231872!2d23.47816054157351!3d41.83571530492031!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14abaeb946493c25%3A0xd6b0eaa049fe85c7!2s2770%20Bansko!5e0!3m2!1sen!2sbg!4v1673246249858!5m2!1sen!2sbg"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>

        <div className="option" onClick={() => onToggle(0)}>
          About the city
        </div>

        <div className="transport-wrapper">
          <div className="transport-heading">General information</div>
          <div className="text border">
            Bansko is a town at the foot of the Pirin Mountains, which are part
            of Pirin National Park, in southwest Bulgaria. It’s a gateway to
            numerous ski and snowboard slopes on Todorka Peak, including the
            challenging Tomba run. Footpaths cross the park’s craggy alpine
            landscape, home to bears and wolves. The park is also known for the
            high-altitude Vihren Peak, with forested ridges and striking glacial
            lakes.
          </div>
        </div>

        <div className="transport-wrapper">
          <div className="transport-heading">Bulgarian cuisine</div>
          <div className="text">
            The cuisine of Bansko is unique for the borders of the town. The
            answer to that is in the development of Bansko as a town of
            merchants in the 18th century. While traveling to Austro-Hungarian
            Empire, Italy and Germany with their caravan, the tradesman of
            Bansko were bringing back influences and inspiration.
          </div>
          <div className="text">
            Thanks to the ladies of the town, who had to run the household
            throughout the year, specific dishes were prepared in Bansko and
            nowhere else. Nowadays those recipes are part of the traditional
            Bulgarian cuisine.
          </div>

          <div className="map">
            <img src={require("../../assets/images/about-the-city.png")} />
          </div>
        </div>

        <div className="option">Slopes</div>

        <div className="transport-wrapper">
          <div className="transport-heading">Opening hours</div>
          <div className="text">Monday - Sunday</div>
          <div className="text">
            Gondola: first ride from Bansko 8:30 AM - last ride from Banderishka
            Polyana 5 PM
          </div>
          <div className="text">
            Lifts: first ride 8:45 AM - last ride 4:15 PM (Lift Banderitsa: 9 AM
            - last ride 4:15 PM, Lift Plato: 9 AM - last ride 4 PM)
          </div>

          <div className="map">
            <img
              src={require("../../assets/images/slopes-map.png")}
              onClick={() => window.open("https://www.banskoski.com/bg/map")}
            />
          </div>
        </div>
      </div>

      <div className="options-wrapper">
        <div
          className={`destination first ${selected === "1" ? "selected" : ""}`}
          onClick={() => {
            setSelected("1");
            onToggle(0);
          }}
        >
          Getting to bansko
        </div>
        <div
          className={`destination ${selected === "2" ? "selected" : ""}`}
          onClick={() => {
            setSelected("2");
            onToggle(0);
          }}
        >
          About the city
        </div>
        <div
          className={`destination last ${selected === "3" ? "selected" : ""}`}
          onClick={() => setSelected("3")}
        >
          Slopes
        </div>
      </div>

      <div className="main-content">
        {selected === "1" && (
          <>
            <div className="info-wrapper">
              <div className={`option-wrapper ${options[0] ? "selected" : ""}`}>
                <h2>By plane</h2>
                {options[0] ? (
                  <p>
                    The most convenient airport is Sofia International /LBSF/.
                    There will be organised, paid transport from there to Bansko
                    for those who request it. Duration of the trip is
                    approximately two hours.
                  </p>
                ) : (
                  <img
                    src={require("../../assets/images/plus-icon.svg").default}
                    onClick={() => onToggle(0)}
                  />
                )}
              </div>

              <div className={`option-wrapper ${options[1] ? "selected" : ""}`}>
                <h2>By car</h2>
                {options[1] ? (
                  <p>
                    Bansko is located in the southwestern part of the country.
                    It is easily accessible from all directions throughout the
                    year. Keep in mind that a vignette is required for driving
                    on the roads. More information on parking options to come at
                    a later stage.
                  </p>
                ) : (
                  <img
                    src={require("../../assets/images/plus-icon.svg").default}
                    onClick={() => onToggle(1)}
                  />
                )}
              </div>

              <div
                className={`option-wrapper last ${
                  options[2] ? "selected" : ""
                }`}
              >
                <h2>By train and bus</h2>
                {options[2] ? (
                  <>
                    <p>
                      Sofia Airport Metro Station is in close proximity to the
                      airport terminal building. Changing trains from Line 1 to
                      Line 2 at Serdika Metro Station, passengers can travel
                      from the airport to Sofia Central Bus and Railway Stations
                      within less than half an hour. For further information on
                      the railway time schedule, see{" "}
                      <span
                        onClick={() => window.open("https://bileti.bdz.bg/")}
                      >
                        BDZ Passenger Services
                      </span>{" "}
                      and for bus schedule see the{" "}
                      <span
                        onClick={() =>
                          window.open(
                            "https://www.centralnaavtogara.bg/index.php#b"
                          )
                        }
                      >
                        Central Bus Station
                      </span>
                      .
                    </p>
                    <p>
                      By bus from Sofia Central Bus Station the estimated travel
                      time to Bansko Bus Station is approximately 3 hours.
                    </p>
                    <p>
                      By train from Sofia Central Railway Station the estimated
                      travel time to Bansko Railway Station is approximately 6
                      hours.
                    </p>
                  </>
                ) : (
                  <img
                    src={require("../../assets/images/plus-icon.svg").default}
                    onClick={() => onToggle(2)}
                  />
                )}
              </div>
            </div>

            <div className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11890.622089231872!2d23.47816054157351!3d41.83571530492031!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14abaeb946493c25%3A0xd6b0eaa049fe85c7!2s2770%20Bansko!5e0!3m2!1sen!2sbg!4v1673246249858!5m2!1sen!2sbg"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </>
        )}

        {selected === "2" && (
          <>
            <div className="info-wrapper">
              <div className={`option-wrapper ${options[0] ? "selected" : ""}`}>
                <h2>General Information</h2>
                {options[0] ? (
                  <p>
                    Bansko is a town at the foot of the Pirin Mountains, which
                    are part of Pirin National Park, in southwest Bulgaria. It’s
                    a gateway to numerous ski and snowboard slopes on Todorka
                    Peak, including the challenging Tomba run. Footpaths cross
                    the park’s craggy alpine landscape, home to bears and
                    wolves. The park is also known for the high-altitude Vihren
                    Peak, with forested ridges and striking glacial lakes.
                  </p>
                ) : (
                  <img
                    src={require("../../assets/images/plus-icon.svg").default}
                    onClick={() => onToggle(0)}
                  />
                )}
              </div>

              <div
                className={`option-wrapper last ${
                  options[1] ? "selected" : ""
                }`}
              >
                <h2>Bulgarian Cuisine</h2>
                {options[1] ? (
                  <>
                    <p>
                      The cuisine of Bansko is unique for the borders of the
                      town. The answer to that is in the development of Bansko
                      as a town of merchants in the 18th century. While
                      traveling to Austro-Hungarian Empire, Italy and Germany
                      with their caravan, the tradesman of Bansko were bringing
                      back influences and inspiration.
                    </p>

                    <p>
                      Thanks to the ladies of the town, who had to run the
                      household throughout the year, specific dishes were
                      prepared in Bansko and nowhere else. Nowadays those
                      recipes are part of the traditional Bulgarian cuisine.
                    </p>
                  </>
                ) : (
                  <img
                    src={require("../../assets/images/plus-icon.svg").default}
                    onClick={() => onToggle(1)}
                  />
                )}
              </div>
            </div>

            <div className="map">
              <img src={require("../../assets/images/about-the-city.png")} />
            </div>
          </>
        )}

        {selected === "3" && (
          <>
            <div className="info-wrapper start">
              <div className="option-wrapper last single">
                <h2>Opening hours</h2>
                <p>Monday - Sunday</p>
                <p>
                  Gondola: first ride from Bansko 8:30 AM - last ride from
                  Banderishka Polyana 5 PM
                </p>
                <p>
                  Lifts: first ride 8:45 AM - last ride 4:15 PM (Lift
                  Banderitsa: 9 AM - last ride 4:15 PM, Lift Plato: 9 AM - last
                  ride 4 PM)
                </p>
              </div>
            </div>

            <div className="map pointer">
              <img
                src={require("../../assets/images/slopes-map.png")}
                onClick={() => window.open("https://www.banskoski.com/bg/map")}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
