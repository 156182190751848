import React from 'react';
import './styles.scss';

export default function PricingPopup({ firstPayment, secondPayment, isMobile, data }) {
  return (
    <div className={`popup-wrapper pricing ${isMobile ? 'mobile' : ''}`}>
      <div className="content-wrapper">
        {/* {isMobile && data && 
        <div className='top-wrapper'>
            <h1>{data?.hotel || 'Cancelation fees'}</h1>
            {data?.map(el => {
              return (
              <div className='flex col'>
                <p className='regular'>{el?.light}</p>
                <p className='bold'>{el?.bold}</p>
              </div>
              )
            })}
        </div>} */}

        {data?.map((el, index) => {
            return (
            <div className={`row-wrapper flex ${index === data.length - 1 ? 'last' : ''}`}>
                <div className='text-wrapper'>
                  <p className='regular'>{el?.light}</p>
                  <p className='bold'>{el?.bold}</p>
                </div>
            </div>
            )
        })}
      </div>

      <div className="img-wrapper">
        <img className="info-icon arrow" src={require("../../assets/images/triangle-arrow.png")} />
      </div>
    </div>
  )
}
