import React from 'react';
import { useState, useEffect } from 'react';
import { getCategoriesAsync, getCategoryRecordsAsync, setLoading } from '../../reducers/results';
import { Header, FooterDownloads, ResultsTabs, ResultsComponent, Loading } from '../../components';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import './styles.scss';

export default function Results() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const categories = useSelector(state => state.results.categories)
    const results = useSelector(state => state.results.records)
    const loading = useSelector(state => state.results.loading);

    const [ selected, setSelected ] = useState('')
    const [ selectedData, setSelectedData ] = useState({})

    const checkResults = (results) => {
      // if (!results?.[0]?._resultsFiles?.length) return false
      let addedResults = results?.map(res => res?._resultsFiles).filter(res => res.length > 0)
      return addedResults?.length > 0 ? false : true
    }

    const changeSelected = (categoryId) => {
      if (categoryId) {
        dispatch(getCategoryRecordsAsync({
          payload: { _id: categoryId },
          onSuccess: (res) => {
            if (Object.values(res)?.length) {
              setSelected(res?.title);
              setSelectedData(res)
              checkResults(res?._scoreboards)
            }
          }
        }))
      }
    }

    useEffect(() => {
      dispatch(setLoading(true))
      dispatch(getCategoriesAsync({
        onSuccess: (res) => {
          if (Object.values(res)?.length) {
            setSelected(res?.title);
            setSelectedData(res)
          }
        }
      }))
    }, []);

  return (
    <div className="results-page-wrapper">
        <Header />

        <div className="content-wrapper">
          <div className="top-wrapper flex">
            <div className="heading-wrapper" onClick={() => navigate("/")}>
              <img src={require("../../assets/images/arrow-back.svg").default} />
              <h4>Back</h4>
            </div>
  
            <ResultsTabs options={categories} selected={selected} selectedData={selectedData} setSelected={(value) => changeSelected(value)} />

            <div className='empty-component' />
          </div>

          <div className={`main-content ${(loading || checkResults(results)) ? 'center' : ''}`}>
            {loading ?
              <Loading noShadow center /> :
              checkResults(results) ?
              <div className='empty-state'>
                <div className='image-wrapper'>
                  <img src={require('../../assets/images/results-empty-state.png')} alt='empty state mountain image' />
                </div>
                <p>No results added yet</p>
              </div> : 
              <ResultsComponent results={results} />}
          </div>
        </div>

        <img className='bottom-img-small' src={require('../../assets/images/mountains-two-ver2.png')} />
        <FooterDownloads />
    </div>
  )
}
