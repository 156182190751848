import React from 'react';
import './styles.scss';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function FooterDownloads(props) {
    const navigate = useNavigate();

    const [ isFinished, setIsFinished ] = useState(true);
    const finishCounterDate = 'March, 16, 2024, 20:00:00';

    useEffect(() => {
      window.scrollTo(0, { behavour: 'smooth' })

      if (Date.parse(finishCounterDate) - Date.parse(new Date()) <= 0) {
        setIsFinished(true)
      }
      else {
        setIsFinished(false)
      }
    }, [])

  return (
    <div className='footer-wrapper downloads'>
        <div className='section top nav'>
            <li onClick={() => props?.aboutRef ? props.scroll(props.aboutRef) : navigate('/')}>About GFL</li>
            <li onClick={() => props?.scheduleRef ? props.scroll(props.scheduleRef) : navigate('/')}>Event timeline</li>
            <li onClick={() => props?.scheduleRef ? props.scroll(props.preGFLRef) : navigate('/')}>Pre-GFL</li>
            <li onClick={() => props?.packageRef ? props.scroll(props.packageRef) : navigate('/')}>PACKAGE</li>
            <li onClick={() => props?.transportationRef ? props.scroll(props.transportationRef) : navigate('/')}>Bansko</li>
            <li onClick={() => props?.transportationRef ? props.scroll(props.pricingRef) : navigate('/')}>Pricing</li>
            <li onClick={() => props?.hotelsRef ? props.scroll(props.hotelsRef) : navigate('/')}>Hotels</li>
            <li onClick={() => props?.contactRef ? props.scroll(props.contactRef) : navigate('/')}>Contact us</li>
            <li onClick={() => props?.partnersRef ? props.scroll(props.partnersRef) : navigate('/')}>Partners</li>

            {/* {!isFinished ?
            <button onClick={() => navigate('/registration-info', {state: 'mobile'})}>Registration</button> : */}
            <button onClick={() => navigate('/results', {state: 'mobile'})}>Races</button>
        </div>
      <div className='section top'>
        <div>DATA PROTECTION POLICY</div>
        <div>IMPRINT-DISCLAIMER</div>
        <div onClick={() => navigate('/downloads')}>DOWNLOADS</div>
      </div>

      <div className='section bottom'>
        <img src={require("../../assets/images/facebook.svg").default} onClick={() => window.open('https://m.facebook.com/GFL-Bansko-2024-103013402685355/')} />
        <img src={require("../../assets/images/instagram.svg").default} onClick={() => window.open('https://www.instagram.com/gfl.2024.bansko/?igshid=YmMyMTA2M2Y%3D')} />
      </div>
      </div>
  )
}
