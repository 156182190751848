import React from "react";
import "./styles.scss";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function Header(props) {
  const [ isNavOpen, setIsNavOpen ] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [ isFinished, setIsFinished ] = useState(true);
  const finishCounterDate = 'March, 16, 2024, 20:00:00';
  // const finishCounterDate = 'February, 19, 2024, 13:05:00';

  const navigateOnClick = (ref) => {
    if (location.pathname !== '/') {
      // navigate('/');
      navigate('/', { state: { ref } });
    }
    else {
      props.scroll(ref);
    }
    
  }

  useEffect(() => {
    if (Date.parse(finishCounterDate) - Date.parse(new Date()) <= 0) {
      setIsFinished(true)
    }
    else {
      setIsFinished(false)
    }
  }, [])

  // const checkDate = () => {
  //   if (Date.parse(finishCounterDate) - Date.parse(new Date()) <= 0) {
  //     setIsFinished(true)
  //   }
  //   else {
  //     setIsFinished(false)
  //   }
  // }

  // useEffect(() => {
  //   if (!isFinished) {
  //     let interval = setInterval(() => checkDate(), 10000);

  //     return () => clearInterval(interval);
  //   }
  // }, [])

  // console.log(location)

  return (
    <div className="header-wrapper">
      <div className="mobile">
        <div className={`nav ${isNavOpen ? 'opened' : ''}`}>
          {!isNavOpen &&
          <img 
          src={require('../../assets/images/responsive-menu.svg').default}
          onClick={() => setIsNavOpen(true)} />}

          {isNavOpen && 

          <>
          <img src={require('../../assets/images/blue-delete-icon.png')}
          onClick={() => setIsNavOpen(false)} />

          <div className="opened-nav">
            <li onClick={() => {
              if (location.pathname === '/') {
                props.scroll(props.aboutRef)
                setIsNavOpen(false)
              }
              else {
                setIsNavOpen(false)
                navigateOnClick('aboutRef')
              }
              }}>About GFL</li>
            <li onClick={() => {
              if (location.pathname === '/') {
                props.scroll(props.scheduleRef)
                setIsNavOpen(false)
              }
              else {
                setIsNavOpen(false)
                navigateOnClick('scheduleRef')
              }
              }}>Event timeline</li>
            {/* <li onClick={() => {
              if (location.pathname === '/') {
                props.scroll(props.preGFLRef)
                setIsNavOpen(false)
              }
              else {
                setIsNavOpen(false)
                navigateOnClick('preGFLRef')
              }
              }}>PRE-GFL</li> */}
            {/* <li onClick={() => {
              if (location.pathname === '/') {
                props.scroll(props.packageRef)
                setIsNavOpen(false)
              }
              else {
                setIsNavOpen(false)
                navigateOnClick('packageRef')
              }
              }}>PACKAGE</li> */}
            <li onClick={() => {
              if (location.pathname === '/') {
                props.scroll(props.transportationRef)
                setIsNavOpen(false)
              }
              else {
                setIsNavOpen(false)
                navigateOnClick('transportationRef')
              }
              }}>BANSKO</li>
            <li onClick={() => {
              if (location.pathname === '/') {
                props.scroll(props.pricingRef)
                setIsNavOpen(false)
              }
              else {
                setIsNavOpen(false)
                navigateOnClick('pricingRef')
              }
              }}>PRICING</li>
            <li onClick={() => {
              if (location.pathname === '/') {
                props.scroll(props.hotelsRef)
                setIsNavOpen(false)
              }
              else {
                setIsNavOpen(false)
                navigateOnClick('hotelsRef')
              }
              }}>Hotels</li>
            <li onClick={() => {
              if (location.pathname === '/') {
                props.scroll(props.contactRef)
                setIsNavOpen(false)
              }
              else {
                setIsNavOpen(false)
                navigateOnClick('contactRef')
              }
              }}>Contact us</li>
            <li onClick={() => {
              if (location.pathname === '/') {
                props.scroll(props.partnersRef)
                setIsNavOpen(false)
              }
              else {
                setIsNavOpen(false)
                navigateOnClick('partnersRef')
              }
              }}>Partners</li>

            {/* {!isFinished ?
            <button onClick={() => navigate('/registration-info', {state: 'mobile'})}>Registration</button> : */}
            <button onClick={() => navigate('/results', {state: 'mobile'})}>Races</button>
          </div>
          </>}
        </div>

        {isNavOpen && <div className="background-wrapper"></div>}
        {!isNavOpen && <img className='logo' src={require('../../assets/images/logo.png')} onClick={() => props?.heroRef ? props.scroll(props?.heroRef) : navigate('/')} />}
      </div>

      <div className="header-section nav">
        <ul className="nav left">
          <li onClick={() => {
             if (location.pathname === '/') {
              props.scroll(props.aboutRef)
              setIsNavOpen(false)
            }
            else {
              setIsNavOpen(false)
              navigateOnClick('aboutRef')
            }
          }}>About GFL</li>
          
          <li onClick={() => {
             if (location.pathname === '/') {
              props.scroll(props.scheduleRef)
              setIsNavOpen(false)
            }
            else {
              setIsNavOpen(false)
              navigateOnClick('scheduleRef')
            }
          }}>EVENT TIMELINE</li>

          {/* <li onClick={() => {
             if (location.pathname === '/') {
              props.scroll(props.preGFLRef)
              setIsNavOpen(false)
            }
            else {
              setIsNavOpen(false)
              navigateOnClick('preGFLRef')
            }
          }}>PRE-GFL</li> */}

          {/* <li onClick={() => {
             if (location.pathname === '/') {
              props.scroll(props.packageRef)
              setIsNavOpen(false)
            }
            else {
              setIsNavOpen(false)
              navigateOnClick('packageRef')
            }
          }}>PACKAGE</li> */}

          <li onClick={() => {
             if (location.pathname === '/') {
              props.scroll(props.pricingRef)
              setIsNavOpen(false)
            }
            else {
              setIsNavOpen(false)
              navigateOnClick('pricingRef')
            }
          }}>Pricing</li>
 <li onClick={() => {
             if (location.pathname === '/') {
              props.scroll(props.transportationRef)
              setIsNavOpen(false)
            }
            else {
              setIsNavOpen(false)
              navigateOnClick('transportationRef')
            }
          }}>Bansko</li>
        </ul>
      </div>

      <div className="header-section">
        <img src={require('../../assets/images/logo.png')} className='logo' onClick={() => props?.heroRef ? props.scroll(props?.heroRef) : navigate('/')} />
      </div>

      <div className="header-section nav">
        <ul className="nav">
          {/* <li onClick={() => {
             if (location.pathname === '/') {
              props.scroll(props.transportationRef)
              setIsNavOpen(false)
            }
            else {
              setIsNavOpen(false)
              navigateOnClick('transportationRef')
            }
          }}>Bansko</li> */}
          <li onClick={() => {
             if (location.pathname === '/') {
              props.scroll(props.hotelsRef)
              setIsNavOpen(false)
            }
            else {
              setIsNavOpen(false)
              navigateOnClick('hotelsRef')
            }
          }}>Hotels</li>
          <li onClick={() => {
             if (location.pathname === '/') {
              props.scroll(props.contactRef)
              setIsNavOpen(false)
            }
            else {
              setIsNavOpen(false)
              navigateOnClick('contactRef')
            }
          }}>Contact us</li>
          <li onClick={() => {
             if (location.pathname === '/') {
              props.scroll(props.partnersRef)
              setIsNavOpen(false)
            }
            else {
              setIsNavOpen(false)
              navigateOnClick('partnersRef')
            }
          }}>Partners</li>
        </ul>

        {/* {!isFinished ?
        <div className="button-wrapper">
          <button onClick={() => window.open('/registration-info')}>Registration</button>
        </div> : */}
        <div className="button-wrapper">
          <button onClick={() => navigate('/results')}>Races</button>
        </div>
      </div>

    </div>
  );
}
