import React from 'react'
import './styles.scss'
import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Countdown } from '../Countdown'

export default function HeroSection({ heroRef }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [ isFinished, setIsFinished ] = useState(true);
  const finishCounterDate = 'March, 16, 2024, 20:00:00';
  // const finishCounterDate = 'February, 19, 2024, 13:05:00';

  useEffect(() => {
    if (Date.parse(finishCounterDate) - Date.parse(new Date()) <= 0) {
      setIsFinished(true)
    }
    else {
      setIsFinished(false)
    }
  }, [])
  // }, [ isFinished ])

  return (
    <div className='hero-section-wrapper' ref={heroRef}>
      <div className='text-section'>
        <h1 className='small'>March 16 - 23 2024</h1>
        <h1 className='large'>Bansko, bulgaria</h1>
        <h1 className='medium'>49th golden flight level</h1>

        {!isFinished ? 
        <div className='countdown-section'>
          <Countdown deadline={finishCounterDate} setIsFinished={(value) => setIsFinished(value)} />
        </div> : 
        <div className="button-wrapper desktop">
          <button onClick={() => navigate('/results')}>Races</button>
        </div>}
      </div>

      {isFinished && 
      <div className="button-wrapper mobile">
          <button onClick={() => navigate('/results')}>Races</button>
      </div>}

      <div className='image-section'>
        <img src={require('../../assets/images/ski-hero-section.svg').default} />
      </div>
    </div>
  )
}
