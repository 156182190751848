import React from "react";
import "./styles.scss";

export default function TableCardsMobile({ data }) {
  return (
    <>
      {data?.map((el, i) => { 
        return (
          <div className="table-card-wrapper" key={`table-card-mobile-wrapper-${el.location}-${i}`}>
             {el?.year && 
             <div className="row">
               <div className="table-card-title">Year</div>
               <div className="table-card-content">{el.year}</div>
             </div>}

             {el?.organisation &&
             <div className="row">
               <div className="table-card-title">Organisation committee</div>
               <div className="table-card-content">{el.organisation}</div>
             </div>}

             {el?.location && 
             <div className="row">
               <div className="table-card-title">Location</div>
               <div className="table-card-content">{el.location}</div>
             </div>}

             {el?.winner &&
             <div className="row">
               <div className="table-card-title">Winner</div>
               <div className="table-card-content">{el.winner}</div>
             </div>}

             {el?.spiritWinner &&
             <div className="row">
               <div className="table-card-title">Spirit award winner</div>
               <div className="table-card-content">{el.spiritWinner}</div>
             </div>}
          </div>
        );
      })}
    </>
  );
}
