import { createSlice } from '@reduxjs/toolkit'
import httpClient from '../epics/registration';

const initialState = {
    team: "",
	teamEmail: "",
	country: "",
	hotel: "",
	singleRoomCount: 0,
	doubleRoomCount: 0,
	tripleRoomCount: 0,
    quadrupleRoomCount: 0,
    totalPrice: 0,
    selectedTab: 'general information',
    members: [],
    currentMemberId: '',
    preGFLRegistration: {},
    isLoading: false
}

export const registration = createSlice({
    name: 'registration',
    initialState,
    reducers: {
        createRegistration: (state, action) => ({ ...state, ...action.payload, members: state.members }),
        createPreGFLRegistration: (state, action) => ({ ...state, preGFLRegistration: { ...action.payload } }),
        updateRegistration: (state, action) => ({ ...action.payload, selectedTab: state.selectedTab, currentMemberId: state.currentMemberId }),
        addTeamMember: (state, action) => ({ ...action.payload, selectedTab: state.selectedTab, currentMemberId: state.currentMemberId }),
        editTeamMember: (state, action) => ({ ...action.payload, selectedTab: state.selectedTab, currentMemberId: state.currentMemberId }),
        deleteTeamMember: (state, action) => ({ ...action.payload, selectedTab: state.selectedTab, currentMemberId: state.currentMemberId }),
        completeRegistration: (state, action) => ({ ...state, ...action.payload, isLoading: false }),
        getRegistration: (state, action) => ({ ...action.payload, selectedTab: state.selectedTab, currentMemberId: state.currentMemberId }),
        postStats: (state, action) => ({...state}),
        changeTab: (state, action) => ({ ...state, selectedTab: action.payload, currentMemberId: state.currentMemberId, isLoading: false }),
        setMemberId: (state, action) => ({ ...state, selectedTab: state.selectedTab, currentMemberId: action.payload }),
        setLoading: (state, action) => ({ ...state, isLoading: action.payload }),
        resetState: () => ({ team: "", teamEmail: "", country: "", hotel: "", singleRoomCount: 0, doubleRoomCount: 0, tripleRoomCount: 0, quadrupleRoomCount: 0, totalPrice: 0, selectedTab: 'general information', members: [], currentMemberId: '', isLoading: false })
    }
})

export const createRegistrationAsync = ({ payload }) => async (dispatch) => {
    // const user = localStorage.getItem('user')
    // const { data } = await axios.post(`${API_URL}/registrations`, payload)
    const { data } = await httpClient.post('/registrations', payload)  
    dispatch(createRegistration({ ...data.payload }))
}

export const createPreGFLRegistrationAsync = ({ payload }) => async (dispatch) => {
    // const user = localStorage.getItem('user')
    // const { data } = await axios.post(`${API_URL}/registrations`, payload)
    const { data } = await httpClient.post('/registrations/single', payload)  
    dispatch(createPreGFLRegistration({ ...data.payload }))
}

export const updateRegistrationAsync = ({ payload }) => async (dispatch, getState) => {
    const registrationId = getState().registration.id;
    const { data } = await httpClient.put(`/registrations/${registrationId}`, payload) 
    dispatch(updateRegistration({ ...data.payload }))
}

export const addTeamMemberAsync = ({ payload, onSuccess }) => async (dispatch) => {
    const { data } = await httpClient.post('/registrations/members', payload)
    
    if (data.success) {
      dispatch(addTeamMember({ ...data.payload }))
      if (onSuccess) onSuccess()
    }
}

export const editTeamMemberAsync = ({ payload }) => async (dispatch, getState) => {
    const currentMemberId = getState().registration.currentMemberId;
    const { data } = await httpClient.put(`/registrations/members/${currentMemberId}`, payload) 
    dispatch(editTeamMember({ ...data.payload }))
}

export const deleteTeamMemberAsync = ({ payload }) => async (dispatch, getState) => {
    const currentMemberId = getState().registration.currentMemberId;
    const { data } = await httpClient.delete(`/registrations/members/${currentMemberId}`, payload) 
    dispatch(deleteTeamMember({ ...data.payload }))
}

export const completeRegistrationAsync = ({ payload }) => async (dispatch, getState) => {
    const registrationId = getState().registration.id;
    const { data } = await httpClient.post(`/registrations/${registrationId}/complete`, payload) 
    dispatch(completeRegistration({ ...data.payload }))
    // dispatch(setLoading(false))
}

export const getRegistrationAsync = () => async (dispatch, getState) => {
    const registrationId = getState().registration.id;
    const { data } = await httpClient.get(`/registrations/${registrationId}`) 
    dispatch(getRegistration({ ...data.payload }))
}

export const postStatsAsync = () => async (dispatch) => {
    const { data } = await httpClient.post('/stats') 
    dispatch(postStats({ ...data.payload }))
}


export const {
    createRegistration,
    createPreGFLRegistration,
    updateRegistration,
    addTeamMember,
    editTeamMember,
    deleteTeamMember,
    completeRegistration,
    getRegistration,
    postStats,
    changeTab,
    setMemberId,
    setLoading,
    resetState
} = registration.actions

export default registration.reducer;