import { createSlice } from '@reduxjs/toolkit'
import httpClient from '../epics/registration';

const initialState = {
    categories: [],
    records: [],
    loading: false
}

export const results = createSlice({
    name: 'results',
    initialState,
    reducers: {
        getCategories: (state, action) => ({ ...state, categories: Object.values(action?.payload)?.length ? Object.values(action?.payload) : [] }),
        getCategoryRecords: (state, action) => ({ ...state, records: action?.payload?._scoreboards || [] }),
        setLoading: (state, action) => ({ ...state, loading: action?.payload || false }),
    }
})

export const getCategoriesAsync = ({ onSuccess }) => async (dispatch) => {
    const { data } = await httpClient.get('/results/categories') 
    dispatch(getCategories({ ...data?.payload }))

    // if (data.success && data?.payload?.length) {
    //     dispatch(getCategoryRecordsAsync({ _id: data.payload[0]._id }))
    //     if (onSuccess) onSuccess()
    // }
    // if (data?.success) dispatch(setLoading(false))
    if (data.success && data?.payload?.length) {
        dispatch(getCategoryRecordsAsync({ payload: { _id: data.payload[0]._id }, onSuccess: onSuccess}))
    }
}

export const getCategoryRecordsAsync = ({ payload, onSuccess }) => async (dispatch) => {
    const { data } = await httpClient.get(`/results/categories/${payload?._id}`) 
    dispatch(getCategoryRecords({ ...data.payload }))
    if (data?.success) dispatch(setLoading(false))
    if (data.success && onSuccess) onSuccess(data?.payload)
}

// export const getCategoryRecordsAsync = (payload) => async (dispatch) => {
//     const { data } = await httpClient.get(`/results/categories/${payload?._id}`) 
//     dispatch(getCategoryRecords({ ...data.payload }))
// }

export const {
    getCategories,
    getCategoryRecords,
    setLoading
} = results.actions

export default results.reducer;