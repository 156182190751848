import React from "react";
import "./styles.scss";

export default function FooterRegistration() {
  return (
    <div className="footer-wrapper registration">
      <div className="contacts">
        <div className="contact">
          Email:&nbsp; <span>accommodation@gfl-2024.com</span>
        </div>

        {/* <div className="contact line">
          Email:&nbsp; <span> oc@gfl-2024.com</span>
        </div>
        <div className="contact">
          Phone:&nbsp; <span> to be announced</span>
        </div> */}
      </div>
    </div>
  );
}
