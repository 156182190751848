import React from "react";
import { Header, FooterDownloads } from "../../components";
import "./styles.scss";
import { useNavigate, Link } from "react-router-dom";

export default function Downloads() {
  const navigate = useNavigate();

  return (
    <div className="downloads-page-wrapper">
      <Header />

      <div className="content-wrapper">
        <div className="top-wrapper flex">
          <div className="heading-wrapper" onClick={() => navigate("/")}>
            <img src={require("../../assets/images/arrow-back.svg").default} />
            <h4>Back</h4>
          </div>

          <h1>Downloads</h1>
        </div>

        <div className="main-content">
          <div className="section left">
            <img src={require("../../assets/images/shirts.png")} />
          </div>

          <div className="section right">
            <h1>CREATE unique TEAM t-SHIRTs</h1>
            <p>
            The logo of GFL BANSKO 2024 is available for download as vector graphic. Feel free to use it for the design of your 2024 team shirt (we’re looking forward to your creativity).
            </p>

            <a className="icon-text-wrapper" href='https://drive.google.com/file/d/1mTAbPP_Njo3qGOUpIUpLF8_dAmtYMQBp/view?usp=sharing' target='_blank'>
              <img src={require("../../assets/images/download-icon.svg").default} />
              <div className="text">Download logo</div>
            </a>
          </div>
        </div>
      </div>

      <FooterDownloads />
    </div>
  );
}
