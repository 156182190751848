import React from 'react'
import { useEffect, useRef } from 'react'
import './styles.scss'
import { useLocation } from 'react-router-dom'
import { Header, HeroSection, About, Schedule, PreGFL, Package, Pricing, Transportation, Hotels, Contact, PastEvents, FooterDownloads, Partners } from '../../components'

export default function LandingPage() {
  const heroRef = useRef()
  const aboutRef = useRef()
  const scheduleRef = useRef()
  const preGFLRef = useRef()
  const packageRef = useRef()
  const transportationRef = useRef()
  const hotelsRef = useRef()
  const contactRef = useRef()
  const eventsRef = useRef()
  const pricingRef = useRef()
  const partnersRef = useRef()
  const { state } = useLocation();

  const scroll = (ref) => {
    ref.current?.scrollIntoView({behavior: 'smooth', block: 'start', top: ref.current.offsetTop - 300})
  }

  useEffect(() => {
    if (state?.ref === 'aboutRef') {
      scroll(aboutRef)
    }
    else if (state?.ref === 'scheduleRef') {
      scroll(scheduleRef)
    }
    else if (state?.ref === 'preGFLRef') {
      scroll(preGFLRef)
    }
    // else if (state?.ref === 'packageRef') {
    //   scroll(packageRef)
    // }
    else if (state?.ref === 'transportationRef') {
      scroll(transportationRef)
    }
    else if (state?.ref === 'pricingRef') {
      scroll(pricingRef)
    }
    else if (state?.ref === 'hotelsRef') {
      scroll(hotelsRef)
    }
    else if (state?.ref === 'contactRef') {
      scroll(contactRef)
    }
    else if (state?.ref === 'partnersRef') {
      scroll(partnersRef)
    }
    else {
      window.scrollTo(0, 0)
    }
    // console.log('state', state)
  }, [state])


  return (
    <div className='landing-page-wrapper'>
      <Header scroll={scroll} heroRef={heroRef} aboutRef={aboutRef} scheduleRef={scheduleRef} preGFLRef={preGFLRef}
      packageRef={packageRef} pricingRef={pricingRef} transportationRef={transportationRef} hotelsRef={hotelsRef}
      contactRef={contactRef} eventsRef={eventsRef} partnersRef={partnersRef}
       />
      <HeroSection heroRef={heroRef} />
      <About aboutRef={aboutRef} />
      <Schedule scheduleRef={scheduleRef} />
      {/* <PreGFL preGFLRef={preGFLRef} /> */}
      {/* <Package packageRef={packageRef} /> */}
      <Pricing pricingRef={pricingRef} />
      <Transportation transportationRef={transportationRef} />
      <Hotels hotelsRef={hotelsRef} />
      <Contact contactRef={contactRef} />
      <Partners partnersRef={partnersRef} />
      {/* <PastEvents eventsRef={eventsRef} /> */}
      <FooterDownloads scroll={scroll} aboutRef={aboutRef} scheduleRef={scheduleRef} preGFLRef={preGFLRef}
      packageRef={packageRef} transportationRef={transportationRef} hotelsRef={hotelsRef}
      contactRef={contactRef} eventsRef={eventsRef} pricingRef={pricingRef} partnersRef={partnersRef} />
    </div>
  )
}
