import React from "react";
import "./styles.scss";
import { useState } from "react";
import ScheduleComponent from "./ScheduleComponent";

export default function Schedule({ scheduleRef }) {
  const [option, setOption] = useState("16 March");

  const options = ["16 March", "17 March", "18 March", "19 March", "20 March", "21 March", "22 March", "23 March"];
  const scheduleData = {
    "16 March": [
      { left: "ARRIVAL", right: "" },
      { left: "20:30", right: "Opening Ceremony" },
      { left: "22:00", right: "Live Music & DJ Party @Happy End" },
    ],
    "17 March": [
      { left: "Free skiing", right: "" },
      { left: "17:00", right: "Captains meeting @Kempinski" },
      { left: "20:30", right: "DJ Party @Happy End" },
    ],
    "18 March": [
      { left: "09:00", right: "XC Race" },
      { left: "20:30", right: "PRIZE GIVING CEREMONY XC" },
      { left: "22:00", right: "Balkan & DJ Party @Happy End" },
    ],
    "19 March": [
      { left: "09:00", right: "SB Race - (slope 8)" },
      { left: "20:30", right: "PRIZE GIVING CEREMONY / Karaoke & DJ Party @Happy End" },
    ],
    "20 March": [
      { left: "09:00", right: "Free skiing" },
      // { left: '20:30', right: 'PRIZE GIVING CEREMONY gs - day 1 / DJ Party @Happy End'},
      { left: "20:30", right: "DJ Party @Happy End" },
    ],
    "21 March": [
      // { left: "09:00", right: "GS Race - Day 2 (slope 8)" },
      { left: "09:00", right: "GS Race (slope 8)" },
      { left: "18:00", right: "Captains dinner @VIP Room Banderitsa" },
      // { left: "20:30", right: "Prize giving ceremony GS - Day 2 / Theme party | Live music & DJ party @ Happy Еnd" },
      { left: "20:30", right: "PRIZE GIVING CEREMONY GS / Theme party Live Music & DJ Party @Happy End" },
    ],
    "22 March": [
      { left: "FREE SKIING", right: "" },
      { left: "19:00", right: "Dinner" },
      { left: "20:30", right: "Farewell party / Prize giving ceremony gfl cup, combination cup GEGE CUP / Live Music & DJ Party @ Happy End" },
    ],
    "23 March": [{ left: "DEPARTURE", right: "" }],
  };

  return (
    <div className="schedule-section-wrapper" ref={scheduleRef}>
      <div className="mobile-wrapper">
        <h1 className="main-heading">Event timeline</h1>

        <div className="events-wrapper">
          {Object.keys(scheduleData)?.map((key, i) => {
            return (
              <div className="section">
                <div className="section-title">{key}</div>

                <div className="data-icons-wrapper">
                  <div className="line-wrapper">
                    <img src={require("../../assets/images/ombre-line.svg").default} className="line" />
                  </div>

                  <div className="data-wrapper">
                    {scheduleData[key]?.map((row, index) => {
                      return (
                        <div className="row">
                          {/* <img src={require("../../assets/images/blue-rectangle.svg").default} className="rectangle"/> */}
                          <div className="text-wrapper">
                            <div className="flex">
                              <img src={require("../../assets/images/blue-rectangle.svg").default} className="rectangle" />
                              <div className="bold">{row?.left}</div>
                            </div>
                            {/* <div className="bold">{row?.left}</div> */}
                            {row.right?.includes("/") ? row.right?.split("/")?.map((el, j) => <div className="light margin">{el}</div>) : <div className="light">{row?.right}</div>}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}

          {/* <div className="section">
            <div className="section-title">2023 - Pre-GFL</div>

            <div className="data-icons-wrapper">
              <div className="line-wrapper">
                <img
                  src={require("../../assets/images/ombre-line.svg").default}
                  className="line"
                />
              </div>

              <div className="data-wrapper">
                <div className="row">
                  <img src={require("../../assets/images/blue-rectangle.svg").default} className="rectangle"/>
                  <div className="text-wrapper">
                    <div className="bold">MAY 20TH - 30TH 2023</div>
                    <div className="light">Registration</div>
                  </div>
                </div>

                <div className="row">
                  <img src={require("../../assets/images/blue-rectangle.svg").default} className="rectangle"/>
                  <div className="text-wrapper">
                    <div className="bold">JUNE 19th 2023</div>
                    <div className="light">Payment due</div>
                  </div>
                </div>

                <div className="row">
                  <img src={require("../../assets/images/blue-rectangle.svg").default} className="rectangle"/>
                  <div className="text-wrapper">
                    <div className="bold">september 8th - 10th 2023</div>
                    <div className="light">PRE-GFL event</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <div className="section-title">2024 - gfl</div>

            <div className="data-icons-wrapper">
              <div className="line-wrapper">
                <img
                  src={require("../../assets/images/ombre-line.svg").default}
                  className="line"
                />
              </div>

              <div className="data-wrapper">
                <div className="row">
                  <img src={require("../../assets/images/blue-rectangle.svg").default} className="rectangle" />
                  <div className="text-wrapper">
                    <div className="bold">JUNE 20TH - JULY 2ND 2023</div>
                    <div className="light">Registration</div>
                  </div>
                </div>

                <div className="row">
                  <img src={require("../../assets/images/blue-rectangle.svg").default} className="rectangle" />
                  <div className="text-wrapper">
                    <div className="bold">JUlY 24th 2023</div>
                    <div className="light">First payment (40% + OC Fee) due</div>
                  </div>
                </div>

                <div className="row">
                  <img src={require("../../assets/images/blue-rectangle.svg").default} className="rectangle" />
                  <div className="text-wrapper">
                    <div className="bold">DECEMBER 31ST 2023</div>
                    <div className="light">Second payment due</div>
                  </div>
                </div>

                <div className="row">
                  <img src={require("../../assets/images/blue-rectangle.svg").default} className="rectangle" />
                  <div className="text-wrapper">
                    <div className="bold">March 16th – 23rd 2024</div>
                    <div className="light">GFL Event</div>
                  </div>
                </div>

              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div className="desktop-wrapper">
        <h1>Event timeline</h1>

        <div className="events-wrapper">
          {options?.map((el, index) => {
            return (
              <div className={`option ${option === el ? "selected" : ""}`} onClick={() => setOption(el)}>
                {el}
              </div>
            );
          })}
          {/* <div
            className={`option ${option === "pre-gfl" ? "selected" : ""}`}
            onClick={() => setOption("pre-gfl")}
          >
            2023 - PRE-GFL
          </div>
          <div
            className={`option ${option === "gfl" ? "selected" : ""}`}
            onClick={() => setOption("gfl")}
          >
            2024 - GFL
          </div> */}
        </div>

        <ScheduleComponent data={scheduleData[option]} />

        {option === "pre-gfl" && (
          <div className="schedule-wrapper padding-right">
            <div className="column left">
              <div className="row light first">Registration</div>
              <div className="row bold">June 19th 2023</div>
              <div className="row light">PRE-GFL event</div>
            </div>

            <div className="column center">
              <img src={require("../../assets/images/ombre-line.svg").default} className="line" />

              {Array(3)
                .fill("")
                .map((el, i) => {
                  return <img src={require("../../assets/images/blue-rectangle.svg").default} className={`rectangle ${i === 0 ? "first" : ""}`} key={`${i + 100}`} />;
                })}
            </div>

            <div className="column right">
              <div className="row bold first">MAY 20TH - 30TH 2023</div>
              <div className="row light">Payment due</div>
              <div className="row bold"> september 8th - 10th 2023</div>
              {/* <div className="row light">Approximate expenses</div> */}
            </div>
          </div>
        )}

        {option === "gfl" && (
          <div className="schedule-wrapper padding-left">
            <div className="column left">
              <div className="row light first">Registration</div>
              <div className="row bold">JULY 24th 2023</div>
              <div className="row light">Second payment due</div>
              <div className="row bold">March 16th – 23rd 2024</div>
            </div>

            <div className="column center">
              <img src={require("../../assets/images/ombre-line.svg").default} className="line" />

              {/* <img src={require("../../assets/images/blue-rectangle.svg").default} className='rectangle first' key='11782878898766 + 1000' /> */}
              {/* {Array(3)
              .fill("")
              .map((el, i) => {
                return (
                  <img
                    src={
                      require("../../assets/images/blue-rectangle-filled.svg").default
                    }
                    className='rectangle'
                    key={`${i + 100}`}
                  />
                );
              })} */}

              {Array(4)
                .fill("")
                .map((el, i) => {
                  return <img src={require("../../assets/images/blue-rectangle.svg").default} className={`rectangle ${i === 0 ? "first" : ""}`} key={`${i + 100}`} />;
                })}
            </div>

            <div className="column right">
              <div className="row bold first">JUNE 20TH - JULY 2ND 2023</div>
              <div className="row light">First payment (40% + OC Fee) due</div>
              <div className="row bold">DECEMBER 31ST 2023</div>
              <div className="row light">GFL Event</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
