import React from 'react';
import "./styles.scss";

export default function TableHistory({ tableElements }) {

  return (
    <div className='table-wrapper history'>
        {tableElements?.map((el, i) => {
            return (
                <div className={`row ${el?.year === '2024' ? 'blue' : ''}`} key={`table-history-el-${el?.year}-${i}`}>
                  <div className='element small'>{el?.year || '— —'}</div>
                  <div className='element medium'>{el?.organisation || '— —'}</div>
                  <div className='element medium'>{el?.location || '— —'}</div>
                  <div className='element medium last'>{el?.winner}</div>
                </div>
            )
        })}
      
    </div>
  )
}
