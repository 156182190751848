import React from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';

export default function HeaderRegistration({ registered, registrationType }) {
  const logo = require('../../assets/images/logo.png');
  const navigate = useNavigate();
  
  return (
    <div className='header-wrapper registration'>
      <img src={logo} className='logo' onClick={() => navigate('/')} />
      <div className='center' style={{ fontSize: `${registrationType === 'PRE-GFL' ? '18px' : '20px' }`}}>{registered ? 'SUCCESSFUL REGISTRATION' : (registrationType === 'PRE-GFL' ? 'PRE-GFL REGISTRATION' : 'TEAM REGISTRATION')}</div>
    </div>
  )
}
