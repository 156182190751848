import React from 'react';
import './styles.scss';

export default function PricingPopup({ firstPayment, secondPayment, isMobile, data }) {
  return (
    <div className={`popup-wrapper pricing ${isMobile ? 'mobile' : ''}`}>
      <div className="content-wrapper">
        {isMobile && data && 
        <div className='top-wrapper'>
            <h1>{data?.hotel}</h1>
            <div className='flex col'>
                <div className='regular'>{data?.roomType}</div>
                <div className='bold blue'>{`${data?.pricePerPerson} EUR per Person`}</div>
            </div>
        </div>}
        <div className="row-wrapper flex">
            <div className='text-wrapper'>
              <p className='regular'>First payment :&nbsp;<span className='light'>(40% + 200 EUR OC fee)</span></p>
              <p className='semi-bold'>{`${(isMobile ? data?.firstPayment : firstPayment) || 0} EUR`}</p>
            </div>
        </div>

        <div className="row-wrapper flex last">
            <div className='text-wrapper'>
            <p className='regular'>Second payment</p>
            <p className='semi-bold'>{`${(isMobile ? data?.secondPayment : secondPayment) || 0} EUR`}</p>
            </div>
        </div>
      </div>

      <div className="img-wrapper">
        <img className="info-icon arrow" src={require("../../assets/images/triangle-arrow.png")} />
      </div>
    </div>
  )
}
