import { createSlice } from '@reduxjs/toolkit'
import httpClient from '../epics/registration';

const initialState = {
    accommodations: [],
    competitions: [],
}

export const data = createSlice({
    name: 'data',
    initialState,
    reducers: {
        getCompetitions: (state, action) => ({ ...state, competitions: Object.values(action.payload) }),
        getAccommodations: (state, action) => ({ ...state, accommodations: Object.values(action.payload) }),
    }
})


export const getCompetitionsAsync = () => async (dispatch) => {
    const { data } = await httpClient.get('/registrations/competitions')
    dispatch(getCompetitions({ ...data.payload }))
}

export const getAccommodationsAsync = () => async (dispatch) => {
    const { data } = await httpClient.get('/registrations/accommodations')
    dispatch(getAccommodations({ ...data.payload }))
}



export const {
    getAccommodations,
    getCompetitions
} = data.actions

export default data.reducer;