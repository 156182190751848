import React from 'react';
import "./styles.scss";

export default function TableSpirit({ tableElements }) {
  return (
    <div className='table-wrapper spirit'>
        {tableElements?.map((el, i) => {
            return (
                <div className={`row ${el?.year === '2024' ? 'blue' : ''}`} key={`table-spirit-el-${el?.year}-${i}`}>
                  <div className='element small'>{el?.year || '— —'}</div>
                  <div className='element medium'>{el?.location || '— —'}</div>
                  <div className='element medium last'>{el?.spiritWinner}</div>
                  {/* <div className='element medium last'>{el?.year === '2023' ? <img src={require('../../assets/images/dots.svg').default} className='dots'/> : el?.spiritWinner}</div> */}
                </div>
            )
        })}
      
    </div>
  )
}
