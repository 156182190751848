import React, { useState } from 'react'
import './styles.scss'
import { useEffect } from 'react'
import { RegistrationPopup } from '../../components'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getRegistrationAsync } from '../../reducers/registration'
import { Header, HeaderRegistration, Footer, FooterRegistration, RegistrationComingSoon } from '../../components'
import Sheet from 'react-modal-sheet';

export default function RegistrationInfo() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const registrationInfo = useSelector(state => state.registration);
  const [ open, setOpen ] = useState(false);
  const [ isFinished, setIsFinished ] = useState(false);
  // const [ isFinished, setIsFinished ] = useState(true);
  const [ isRegistrationClosed, setIsRegistrationClosed ] = useState(true);

  let isRegistered = state === 'success' ? true : false;
  // let isMobile = state === 'mobile' ? true : false;
  let isMobile = (window.innerWidth <= 1200) ? true : false;
  const finishCounterDate = 'November, 23, 2023, 00:00:00';
  // const finishCounterDate = 'April, 10, 2023, 00:00:00';
  const closedRegistrationDate = 'March, 16, 2024, 00:00:00';

  // useEffect(() => {
  //   if (Date.parse(finishCounterDate) - Date.parse(new Date()) < 0) {
  //     setIsFinished(true)
  //   }
  //   else {
  //     setIsFinished(false)
  //   }
  // }, [ isFinished ])

  // useEffect(() => {
  //   if (Date.parse(closedRegistrationDate) - Date.parse(new Date()) < 0) {
  //     setIsRegistrationClosed(true)
  //   }
  //   else {
  //     setIsRegistrationClosed(false)
  //   }
  // }, [ isRegistrationClosed ])

  return (
    <>
    <div className={`registration-initial-page-wrapper ${!isFinished ? 'countdown' : ''} ${isMobile ? 'mobile' : ''}`}>

    <HeaderRegistration registered={isRegistered} />

    {!isFinished ? 
    <RegistrationComingSoon finishCounterDate={finishCounterDate} setIsFinished={(value) => setIsFinished(value)} /> :
    
        <div className={`data-registration-wrapper ${isMobile ? 'mobile' : ''} ${isRegistered ? 'registered' : ''}`}>
        {!isMobile && <div className={`card left ${isRegistered ? 'registered' : ''} ${isMobile ? 'mobile' : ''}`}>
          {!isRegistered ?
          <>
            <h1>THIS COULD BE YOU IN BANSKO</h1>
            <img src={require("../../assets/images/Main-Screen.png")} />
          </> :
          <>
            <h1>SEE YOU ON THE SLOPES!</h1>
            <div className='img-wrapper'>
              <img className='registered' src={require("../../assets/images/Successful-Registration.png")} />
            </div>
          </>
        }
        </div>}
    
        {!isRegistered && 
        <div className={`card right ${isRegistered ? 'registered' : ''} ${isMobile ? 'mobile' : ''}`}>
          <h1 className='heading larger'>Register your team and hit the slopes</h1>
          <h1 className='heading smaller'>What you need for the registration</h1>
    
          <div className='list-wrapper'>
            <div className='row'>
              <img src={require("../../assets/images/check-mark.svg").default} />
              <div className='row-item'>Register a team of minimum 2 people</div>
            </div>
            
            <div className='row'>
              <img src={require("../../assets/images/check-mark.svg").default} />
              <div className='row-item'>Assign a team leader</div>
            </div>
    
            <div className='row'>
              <img src={require("../../assets/images/check-mark.svg").default} />
              <div className='row-item'>Choose your hotel</div>
            </div>
    
            <div className='row'>
              <img src={require("../../assets/images/check-mark.svg").default} />
              <div className='row-item'>Select type and amount of rooms</div>
            </div>
    
            <div className='row'>
              <img src={require("../../assets/images/check-mark.svg").default} />
    
              <div className='row-item last'>Enter detailed information for all team members 
                <img className='info-icon' src={require("../../assets/images/info-icon.svg").default}
                onClick={() => setOpen(true)} />
                <RegistrationPopup />
              </div>
    
            </div>
    
          </div>
    
          <button onClick={() => navigate('/registration')}>REGISTER NOW</button>
          <p>By registering, you are agreeing with the <span>T&Cs</span> and <span> Privacy policy</span> of GFL</p>
        </div>}
    
        {isRegistered && 
        <div className='card right registered'>
          <div className='top'>
            <h1 className='heading larger'>YOUR REGISTRATION HAS BEEN</h1>
            <h1 className='heading larger'>SUCCESSFULLY RECEIVED</h1>
      
            <div className='list-wrapper'>
              <div className='row'>Registration number: &nbsp; <span>{registrationInfo.code}</span></div>
              <div className='row'>Team leader: &nbsp; 
                <span>{registrationInfo.members.find(member => member.isTeamLeader === true)?.fullName}</span>
              </div>
              <div className='row'>Team name: &nbsp; <span>{registrationInfo.team}</span></div>
              <div className='row'>Email address: &nbsp; <span>{registrationInfo.members.find(member => member.isTeamLeader === true)?.email}</span></div>
            </div>
          </div>
    
          <p>Please track your email for payment reminders, news and updates!</p>
        </div>}
      </div>
    }
    
    <FooterRegistration />

    <Sheet isOpen={open} onClose={() => setOpen(false)} snapPoints={[0.65]}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <RegistrationPopup isMobile={true} />
          </Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop />
     </Sheet>
    </div>
    </>
  )
}
