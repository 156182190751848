export const isInputValid = (value) => {
    let spaces = value?.trim().length === 0 || false
    let reg = (/[A-Za-z0-9]{2,}/g)
    if (value.length < 2 || spaces) {
      return alert("Input fields must include at least 2 characters")
    } 
    // if (!reg.test(value) || value === '' || spaces) {
    //   return alert("Input fields must include at least 2 characters")
    // } 
    return true
}

export const calculatePayment = (pricePerPerson, peopleCount, paymentType) => {
  let totalPrice = pricePerPerson + 200

  if (peopleCount) {
    totalPrice = pricePerPerson * peopleCount + 200
  }

  if (paymentType === 'first payment') {
    let firstPayment = totalPrice * 0.4 + 200
    return firstPayment
  }

  else if (paymentType === 'second payment') {
    let secondPayment = totalPrice * 0.6
    return secondPayment
  }

  else {
    return
  }
}

export const calculatePaymentPerPerson = (pricePerPerson, paymentType) => {
  let totalPrice = pricePerPerson + 200

  if (paymentType === 'firstPayment') {
    let firstPayment = pricePerPerson * 0.4 + 200
    return firstPayment
  }

  else if (paymentType === 'secondPayment') {
    let secondPayment = pricePerPerson * 0.6
    return secondPayment
  }

  else {
    return
  }
}