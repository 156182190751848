import React from "react";
import { useState } from "react";
import "./styles.scss";

export default function ResultsTabs({ options, selected, setSelected, selectedData }) {
  const [ focusedElement, setFocusedElement ] = useState(false);

  return (
    <div className="results-tabs-wrapper">
      <div className="results-tabs-inner-wrapper desktop">
      {options?.map((option, index) => {
        return (
          <div
            key={`result-option-tab-${index}-${option?._id}`}
            className={`option ${option?.title === selected ? "selected" : ""} ${index === 0 ? 'first' : index === options?.length ? 'last' : ''}`}
            onClick={() => setSelected(option?._id)}
          >
            {option?.title}
          </div>
        )
      })}
      </div>
      <div className="results-tabs-inner-wrapper mobile">
      <div className="input-arrow-wrapper">
          <input
            type="text"
            value={selected}
            onClick={() => setFocusedElement(!focusedElement)}
            onChange={() => setSelected(selectedData?._id)}
            onBlur={() => {
              setTimeout(() => {
                setFocusedElement(false);
              }, 150);
            }}>
              
            </input>

            <img
              className={`arrow ${focusedElement ? 'selected' : ''}`}
              src={require("../../assets/images/blue-arrow.svg").default}
              onClick={() => setFocusedElement(!focusedElement)}
            />

            {focusedElement && (
              <div className="dropdown-wrapper">
                {options?.map((el, i) => {
                  return (
                    <div
                      key={i + 109}
                      className="dropdown-element"
                      onClick={() => {
                        setSelected(el?._id);
                        setFocusedElement(false);
                      }}
                    >
                      {el?.title}
                    </div>
                  );
                })}
              </div>
            )}
      </div>
      </div>
    </div>
  );
}
