import React from "react";
import "./styles.scss";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Header, Footer, TableHistory, TableSpirit, TableHeader, TableCardsMobile } from "../../components";

export default function History() {
  const navigate = useNavigate();

  const historyTableHeaderElements = [
    { name: "Year", size: "small" },
    { name: "Organisation committee", size: "medium" },
    { name: "Location", size: "medium" },
    { name: "Winner", size: "medium" },
  ];
  const spiritDataTableHeaderElements = [
    { name: "Year", size: "small" },
    { name: "Location", size: "medium" },
    { name: "Spirit award winner", size: "medium" },
  ];

  const historyTableData = [
    {
      year: "1976",
      organisation: "Vienna",
      location: "Eben im Pongau",
      winner: "Vienna",
    },
    {
      year: "1977",
      organisation: "Vienna",
      location: "Eben im Pongau",
      winner: "Munich",
    },
    {
      year: "1978",
      organisation: "Vienna",
      location: "Eben im Pongau",
      winner: "Vienna",
    },
    {
      year: "1979",
      organisation: "Vienna",
      location: "Eben im Pongau",
      winner: "Stuttgart",
    },
    {
      year: "1980",
      organisation: "Vienna",
      location: "Eben im Pongau",
      winner: "Munich",
    },
    {
      year: "1981",
      organisation: "Zurich",
      location: "Wеngen",
      winner: "Zurich",
    },
    {
      year: "1982",
      organisation: "Vienna",
      location: "Eben im Pongau",
      winner: "Zurich",
    },
    {
      year: "1983",
      organisation: "Milano",
      location: "Bardonecchia",
      winner: "Aix-en-Provence",
    },
    {
      year: "1984",
      organisation: "Zagreb",
      location: "Bоhini",
      winner: "Rovaniemi",
    },
    {
      year: "1985",
      organisation: "Aix-en-Provence",
      location: "La Plagne",
      winner: "Rovaniemi",
    },
    {
      year: "1986",
      organisation: "Geneva",
      location: "Vilars",
      winner: "Geneva",
    },
    {
      year: "1987",
      organisation: "Vienna",
      location: "Eben im Pongau",
      winner: "Austria",
    },
    {
      year: "1988",
      organisation: "Milano",
      location: "Falcade",
      winner: "Rovaniemi",
    },
    {
      year: "1989",
      organisation: "Ljubljana",
      location: "Kranjska Gora",
      winner: "Austria",
    },
    {
      year: "1990",
      organisation: "Vienna",
      location: "Eben im Pongau",
      winner: "Austria",
    },
    {
      year: "1991",
      organisation: "Milano",
      location: "Falcade",
      winner: "Rovaniemi",
    },
    {
      year: "1992",
      organisation: "Zurich",
      location: "Vals",
      winner: "Rovaniemi",
    },
    {
      year: "1993",
      organisation: "Paris",
      location: "Val d’Isère",
      winner: "Austria",
    },
    {
      year: "1994",
      organisation: "Munich",
      location: "Lengries",
      winner: "Rovaniemi",
    },
    {
      year: "1995",
      organisation: "Vienna",
      location: "Eben im Pongau",
      winner: "Austria",
    },
    {
      year: "1996",
      organisation: "Scandinavia",
      location: "Riksgränsen",
      winner: "Geneva",
    },
    {
      year: "1997",
      organisation: "Geneva",
      location: "Villars",
      winner: "Geneva",
    },
    {
      year: "1998",
      organisation: "Maashtricht",
      location: "Maria Alm",
      winner: "Aixtrateam",
    },
    {
      year: "1999",
      organisation: "Belgrade",
      location: "Kopaonik (cancelled)",
      winner: "— —",
    },
    {
      year: "2000",
      organisation: "Canada",
      location: "Banff",
      winner: "Aixtrateam",
    },
    {
      year: "2001",
      organisation: "Slovenia",
      location: "Rogla",
      winner: "cancelled",
    },
    {
      year: "2002",
      organisation: "Madrid",
      location: "Sierra Nevada",
      winner: "Finland",
    },
    {
      year: "2003",
      organisation: "Finland",
      location: "Levi",
      winner: "Finland",
    },
    {
      year: "2004",
      organisation: "Belgrade",
      location: "Kopeonik",
      winner: "Karlsruhe",
    },
    {
      year: "2005",
      organisation: "France",
      location: "Chamonix",
      winner: "Finland",
    },
    {
      year: "2006",
      organisation: "Roma",
      location: "Canazei",
      winner: "Finland",
    },
    {
      year: "2007",
      organisation: "Frankfurt/Karlsruhe",
      location: "Oberstdorf",
      winner: "cancelled",
    },
    {
      year: "2008",
      organisation: "Austria",
      location: "Nassfeld",
      winner: "Finland",
    },
    {
      year: "2009",
      organisation: "Maasricht",
      location: "Passo Tonale",
      winner: "Finland",
    },
    {
      year: "2010",
      organisation: "Switzerland",
      location: "Engelberg",
      winner: "Switzerland",
    },
    {
      year: "2011",
      organisation: "USA",
      location: "Lake Tahoe",
      winner: "Austria/Finland",
    },
    {
      year: "2012",
      organisation: "Munich",
      location: "Garmisch-Partenkirchem",
      winner: "Warsaw",
    },
    {
      year: "2013",
      organisation: "Finland",
      location: "Levi",
      winner: "Padova/Blogna",
    },
    {
      year: "2014",
      organisation: "Slovenia",
      location: "Kranjska Gora",
      winner: "Warsaw",
    },
    {
      year: "2015",
      organisation: "France",
      location: "Les Arcs",
      winner: "Switzerland",
    },
    {
      year: "2016",
      organisation: "Padova",
      location: "Bruneck",
      winner: "Poland",
    },
    {
      year: "2017",
      organisation: "Belgrade",
      location: "Kopaonik",
      winner: "Moscow",
    },
    {
      year: "2018",
      organisation: "Sweden",
      location: "Riksgränsen",
      winner: "Switzerland",
    },
    {
      year: "2019",
      organisation: "Russia",
      location: "Sochi",
      winner: "Russia",
    },
    {
      year: "2020",
      organisation: "Switzerland",
      location: "Laax",
      winner: "Russia",
    },
    {
      year: "2021",
      organisation: "Munich",
      location: "Galtür (cancelled)",
      winner: "— —",
    },
    {
      year: "2022",
      organisation: "Milano",
      location: "La Thuile",
      winner: "Aixtrateam",
    },
    { year: "2023", organisation: "Munich", location: "Galtür", winner: "Switzerland" },
    { year: "2024", organisation: "Bulgaria", location: "Bansko", winner: "" },
  ];

  const spiritTableData = [
    {
      year: "2012",
      location: "Garmish-Partenkirchen",
      spiritWinner: "Air Chance",
    },
    {
      year: "2013",
      location: "Levi",
      spiritWinner: "Matti Jänkälä",
    },
    {
      year: "2014",
      location: "Kranjska Gora",
      spiritWinner: "Pink Team Reims",
    },
    {
      year: "2015",
      location: "Les Arcs",
      spiritWinner: "Kontrolerski Team Poland",
    },
    {
      year: "2016",
      location: "Bruneck",
      spiritWinner: "España Team GFL",
    },
    {
      year: "2017",
      location: "Kopaonik",
      spiritWinner: "España Team GFL",
    },
    {
      year: "2018",
      location: "Riksränsen",
      spiritWinner: "GFL Team Switzerland",
    },
    {
      year: "2019",
      location: "Sochi",
      spiritWinner: "Munich Snowteam",
    },
    {
      year: "2020",
      location: "Laax",
      spiritWinner: "Rhein Radar Farting Huskies",
    },
    {
      year: "2021",
      location: "Galtur (cancelled)",
      spiritWinner: "— —",
    },
    {
      year: "2022",
      location: "La Thuile",
      spiritWinner: 'the “GFL-Community”',
    },
    {
      year: "2023",
      location: "Galtür",
      spiritWinner: "Team Hamburg",
    },
    {
      year: "2024",
      location: "Bansko",
      spiritWinner: "— —",
    },
  ];

  const extraInfo = {
    history: [
      "* GFL 1999 cancelled due to Kosovo war",
      "* GS race 2001 cancelled due to rain and icy conditions",
      "* GS race 2007 cancelled due to storm “Kyrill”",
      "* GFL 2021 cancelled due to Covid-19 pandemic",
    ],
  };

  useEffect(() => {
    window.scrollTo(0, { behavour: 'smooth' })
  }, []);

  
  return (
    <div className="screen-wrapper">
      <Header />
      <div className="history-screen-wrapper">
        <div className="top-wrapper">
          <div className="col-text margin-right">
            <div className="button-wrapper" onClick={() => navigate("/")}>
              <img
                src={require("../../assets/images/arrow-back.svg").default}
              />
              <h4>Back</h4>
            </div>

            <div className="statistic-component-wrapper">
              <h1>GFL HISTORY</h1>

              <div className="text">
                Scroll through the history table and see where Golden Flight
                Level was hosted since 1976. Find all the organisation
                committees and winning teams for every year.
              </div>

              <div className="rows">
                <div className="row">
                  <img
                    src={require("../../assets/images/calendar.svg").default}
                    className='calendar'
                  />
                  <div className="text">
                    <span>48</span>events
                  </div>
                </div>

                <div className="row">
                  <img
                    src={
                      require("../../assets/images/location-icon.svg").default
                    }
                  />
                  <div className="text">
                    <span>33</span>different locations
                  </div>
                </div>

                <div className="row">
                  <img src={require("../../assets/images/users.svg").default} />
                  <div className="text">
                    <span>26</span>organisation committees
                  </div>
                </div>
              </div>

              <div className="extra-info-wrapper">
                {extraInfo?.history?.map((text, index) => {
                  return (
                    <div className="extra-info-row" key={index + 12 * 7}>
                      {text}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="col-table desktop">
            <TableHeader tableHeaderElements={historyTableHeaderElements} />
            <TableHistory tableElements={historyTableData} />
          </div>
          <div className="col-table mobile">
            <TableCardsMobile data={historyTableData} />
          </div>
        </div>

        <div className="bottom-wrapper">
            <div className="col-table margin-right desktop">
              <TableHeader tableHeaderElements={spiritDataTableHeaderElements} />
              <TableSpirit tableElements={spiritTableData} />
            </div>

            <div className="col-table mobile">
              <TableCardsMobile data={spiritTableData} />
           </div>
          <div className="col-text">
            <div className="statistic-component-wrapper margin">
              <h1>GFL SPIRIT</h1>
              <div className="text">
                Since 2012 the GFL Spirit Award honours the socially most
                committed team of the Golden Flight Level. The winning team gets
                to take home the challenge cup and to hold it for one year. See
                below who won the cup in which year.
              </div>

              <div className="rows">
                <div className="row">
                  <img
                    src={require("../../assets/images/calendar.svg").default}
                    className='calendar'
                  />
                  <div className="text">
                    <span>12</span>years
                  </div>
                </div>

                <div className="row">
                  <img
                    src={
                      require("../../assets/images/location-icon.svg").default
                    }
                  />
                  <div className="text">
                    <span>12</span>different locations
                  </div>
                </div>
              </div>

              <div className="extra-info-wrapper">
                 <div className="extra-info-row">* GFL 2021 cancelled due to Covid-19 pandemic</div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
