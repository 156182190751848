import React from "react";
import "./styles.scss";

export default function Contact({ contactRef }) {
  const contactData = [
    {
      img: 'Bogomil_Slavchev',
      background: '1',
      field: 'President of the OC & Finances',
      name: 'Bogomil Slavchev',
      emails: ['oc@gfl-2024.com', 'finances@gfl-2024.com'],
    },
    {
      img: 'Veronika_Solakova',
      background: '1',
      field: 'Finances',
      name: 'Veronika Solakova',
      emails: ['finances@gfl-2024.com'],
    },
    {
      img: 'Dragomir_Dinev',
      background: '2',
      field: 'Tansportation',
      name: 'Dragomir Dinev',
      emails: ['transportation@gfl-2024.com'],
    },
    {
      img: 'Yordan_Yonchev',
      background: '2',
      field: 'Tansportation',
      name: 'Yordan Yonchev',
      emails: ['transportation@gfl-2024.com'],
    },
    {
      img: 'Ivan_Todorov',
      background: '3',
      field: 'Accommodation',
      name: 'Ivan Todorov',
      emails: ['accomodation@gfl-2024.com'],
    },
    {
      img: 'Georgi_Sidov',
      background: '3',
      field: 'Accommodation',
      name: 'Georgi Sidov',
      emails: ['accomodation@gfl-2024.com'],
    },
    {
      img: 'Svetlin_Tsvetanov',
      background: '4',
      field: 'Races',
      name: 'Svetlin Tsvetanov',
      emails: ['races@gfl-2024.com'],
    },
    {
      img: 'Georgi_Doganov',
      background: '4',
      field: 'Races',
      name: 'Georgi Doganov',
      emails: ['races@gfl-2024.com'],
    },
  ]
  return (
    <div className="contact-section-wrapper" ref={contactRef}>
      <h1>Contact us</h1>
      <p className="subtitle">Our team is here for you, so do not hesitate to reach out!</p>

      <div className="content-wrapper">
        {contactData?.map((contact, index) => {
          return (
            <div className="card-wrapper" key={index}>
              <div className={`img-wrapper version-${contact?.background}`}>
                {contact?.img && <img src={require(`../../assets/images/${contact?.img}.png`)} alt="contact photo" />}
              </div>
              <div className="text-wrapper">
                <p className="margin">{contact?.field}</p>
                <p className="bold">{contact?.name}</p>
                {contact?.emails?.map((el, i) => <p className="regular" key={`p-${i}`}>{el}</p>)}
              </div>
            </div>
          )
        })}
      </div>

      {/* <div className="data-wrapper">
        <h1>Contact us</h1>
        <p>Our team is here for you, so do not hesitate to reach out!</p>

        <div className="contacts-info-wrapper">
          <div className="contacts light">
            <div className="flex">
              <img src={require('../../assets/images/email.svg').default}/>
              <h3>Organisation committee</h3>
            </div>
            <h3 className="email">oc@gfl-2024.com</h3>
          </div>

          <div className="contacts light">
            <div className="flex">
              <img src={require('../../assets/images/email.svg').default}/>
              <h3>Finances</h3>
            </div>
            <h3 className="email">finances@gfl-2024.com</h3>
          </div>

          <div className="contacts light">
            <div className="flex">
              <img src={require('../../assets/images/email.svg').default}/>
              <h3>Transportation</h3>
            </div>
            <h3 className="email">transportation@gfl-2024.com</h3>
          </div>

          <div className="contacts light">
            <div className="flex">
              <img src={require('../../assets/images/email.svg').default}/>
              <h3>Accommodation</h3>
            </div>
            <h3 className="email">accommodation@gfl-2024.com</h3>
          </div>

          <div className="contacts light">
            <div className="flex">
              <img src={require('../../assets/images/email.svg').default}/>
              <h3>Races</h3>
            </div>
            <h3 className="email">races@gfl-2024.com</h3>
          </div>

          <div className="contacts dark">
            <div className="contact-row line cursor" onClick={() => window.open('https://m.facebook.com/GFL-Bansko-2024-103013402685355/')} >
                <img src={require('../../assets/images/facebook.svg').default}/>:&nbsp; <span>GFL BANSKO 2024</span>
            </div>
            <div className="contact-row cursor" onClick={() => window.open('https://www.instagram.com/gfl.2024.bansko/?igshid=YmMyMTA2M2Y%3D')}>
                <img src={require('../../assets/images/instagram.svg').default}/>:&nbsp; <span>Official GFL 2024</span>
            </div>
          </div>

        </div>
      </div>

      <div className="img-wrapper">
        <img className="large-illustration" src={require("../../assets/images/ski-contact-section.png")}/>
      </div> */}
    </div>
  );
}
