import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LandingPage from './screens/LandingPage/LandingPage'
import RegistrationPage from "./screens/RegistrationPage/RegistrationPage";
import { TeamMember } from "./components";
import { RegistrationInfo } from "./screens";
import { Registration, Downloads, History, PreGFLRegistrationInfo, PreGFLRegistration, PreGFLRegistrationPage, Results } from "./screens";
import "./App.css";
import { postStatsAsync } from "./reducers/registration";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

function App() {
  const dispatch = useDispatch();
  const [ isFinished, setIsFinished ] = useState(true)
  // const finishCounterDate = 'November, 21, 2022, 15:50:40';
  const finishCounterDate = 'February, 16, 2024, 20:00:00';
  // const finishCounterDate = 'March, 16, 2024, 20:00:00';

  useEffect(() => {
    setTimeout(() => {
      dispatch(postStatsAsync())
    }, 5000)
  }, [])

  useEffect(() => {
    if (Date.parse(finishCounterDate) - Date.parse(new Date()) < 0) {
      setIsFinished(true)
    }
    else {
      setIsFinished(false)
    }
  }, [])
  // }, [ isFinished ])

  return (
    <>
    {/* <Provider> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/downloads" element={<Downloads />} />
          <Route path="/history" element={<History />} />
          <Route path="/results" element={<Results />} />
          <Route path="/registration-info" element={<RegistrationInfo />} />
          {/* <Route path="/pre-gfl-registration-info" element={<PreGFLRegistrationInfo />} /> */}
          {/* <Route path="/pre-gfl-registration" element={<PreGFLRegistration />} /> */}
          {/* <Route path="/pre-gfl-registration" element={<PreGFLRegistrationPage />} /> */}

          {/* {isFinished && <Route path="/registration" element={<RegistrationPage />} />}
          {isFinished && <Route path="/registration/team-member" element={<TeamMember />} />}
          {isFinished && <Route path="/registration/team-member/:memberId" element={<TeamMember />} />} */}
          <Route path="*" element={<Navigate to="/" replace />} />

          {/* <Route path="/" element={<LandingPage />} />
          <Route path="/registration" element={<RegistrationPage />} />
          <Route path="/registration/gfl" element={<Registration />} />
          <Route path="/registration/team-member" element={<TeamMember />} />
          <Route path="*" element={<Navigate to="/" replace />} /> */}
        </Routes>
      </BrowserRouter>
    {/* </Provider> */}
    </>
  );
}

export default App;
