import React from 'react';
import './styles.scss';
import moment from 'moment';
import { Countdown } from '../Countdown';
import { useState, useEffect } from 'react';

export default function RegistrationComingSoon({ finishCounterDate, setIsFinished }) {
  const [ isRegistrationClosed, setIsRegistrationClosed ] = useState(true);
  const openRegistrationDate = 'July, 3, 2023, 00:00:00';
  const closedRegistrationDate = 'March, 16, 2024, 00:00:00';

  useEffect(() => {
    if (Date.parse(openRegistrationDate) - Date.parse(new Date()) < 0) {
      setIsRegistrationClosed(true)
    }
    else {
      setIsRegistrationClosed(false)
    }
  }, [ isRegistrationClosed ])

  return (
    <div className='registration-counter-wrapper'>
      {/* {!isRegistrationClosed ?
      <div className='card-wrapper'>
        <h1>REGISTRATION IS OPEN</h1>
        <h2>Contact your team captain to register</h2>

        <div className='coming-soon-img-wrapper'>
           <img src={require("../../assets/images/Main-Screen.png")} />
        </div>
      </div>
      :
      <div className='card-wrapper'>
        <h1>REGISTRATION IS CLOSED</h1>
        <div className='closed-registration-img'>
          <img src={require("../../assets/images/Main-Screen.png")} />
        </div>
      </div>} */}
      
      <div className='card-wrapper'>
        <h1>REGISTRATION IS CLOSED</h1>
        <h2>Contact your captain for any changes</h2>

        <div className='coming-soon-img-wrapper'>
           <img src={require("../../assets/images/Main-Screen.png")} />
        </div>
      </div>



      <img src={require('../../assets/images/mountains-registration.svg').default} />
    </div>
  )
}
