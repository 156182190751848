import React from 'react';
import './styles.scss';

export default function Partners({ partnersRef }) {
    const data = [
      {
        logo: 'logo-Bulatca',
        link: 'https://bulatca.org/bg/bulatca_bg/'
      },
      {
        logo: 'logo-Balkan-holidays',
        link: 'https://www.balkanholidays.co.uk/'
      },
      {
        logo: 'logo-Bansko',
        link: 'https://www.banskoworldcup.com/'
      },
      {
        logo: 'logo-Vist',
        link: 'https://vist.it/'
      },
      {
        logo: 'logo-Fidweb',
        link: 'https://fidweb.net/'
      },
      {
        logo: 'logo-Starosel',
        link: 'https://www.starosel.com/'
      },
      {
        logo: 'logo-Kamenitsa',
        link: 'https://kamenitza.bg/'
      },
      {
        logo: 'logo-Drone',
        link: 'https://droneshow.bg/'
      },
      {
        logo: 'logo-B2Y',
        link: 'https://www.b2ycreative.com/'
      },
      {
        logo: 'logo-Danieli',
        link: 'https://www.facebook.com/people/Danielli-Art/100035365436913/'
      },
      {
        logo: 'logo-Dachi',
        link: 'http://www.dachi-bg.com/'
      },
      {
        logo: 'logo-Sky',
        link: 'https://sky-r.com/en/'
      },
      {
        logo: 'logo-Print',
        link: 'https://print93.bg/'
      },
      {
        logo: 'logo-Lepka',
        link: 'https://lepka.bg/'
      },
      {
        logo: 'logo-Boriana',
        link: 'https://borianadance.com/en/'
      },
      {
        logo: 'logo-Amlifestyle',
        link: 'https://www.instagram.com/amlifestyle_ltd/?igsh=N2N6eXhuZzF0MXVu'
      },
      {
        logo: 'logo-Kerana',
        link: 'https://www.instagram.com/keranakosmos/?igsh=bTJuYzJpZzQweXkz'
      },
      {
        logo: 'logo-NFA',
        link: 'https://bulgare.net/'
      },
    ]
  return (
    <div className="partners-section-wrapper" ref={partnersRef}>
      <h1>Partners</h1>

      <div className='content-wrapper'>
        <h2>Co-organizer</h2>
        <div className='card-wrapper wide margin' onClick={() => window.open('https://www.bulatsa.com/en/')}>
            <img src={require(`../../assets/images/logo-Bulatsa.png`)} alt='partner logo' />
        </div>

        <h2>Partners</h2>
        <div className='cards-wrapper'>
            {data?.map((el, index) => {
                return (
                  <div className='card-wrapper' key={`partner-logo-${index}`} onClick={() => window.open(el?.link)}>
                    {el?.logo && <img className='full-width' src={require(`../../assets/images/${el?.logo}.png`)} alt='partner logo image' />}
                  </div>
                )
            })}
        </div>

      </div>
    </div>
  )
}
