import React from 'react';
import "./styles.scss";

export default function ScheduleComponent({ data }) {
  return (
    <div className="schedule-wrapper">
    <div className="column left">
      {data?.map((el, i) => {
            return <div className={`row ${i % 2 === 0 ? 'bold' : 'light'} ${i === 0 ? 'first' : ''}`}>{el?.left}</div>
       })}
    </div>

    <div className="column center">
      <img src={require("../../assets/images/ombre-line.svg").default} className="line"/>

      {Array(data?.length).fill("").map((el, i) => {
          return (
            <img src={require("../../assets/images/blue-rectangle.svg").default} className={`rectangle ${i === 0 ? 'first' : ''}`} key={`${i + 100}`}/>
          );
        })}
    </div>

    <div className="column right">
        {data?.map((el, i) => {
            return (
              <>
              {el.right?.includes('/') ?
              el.right?.split('/')?.map((row, index) => <div className={`row ${i % 2 === 0 ? 'light' : 'bold'} ${index !== el.right?.split('/')?.length - 1 ? 'small-margin' : ''}`}>{row}</div>) :
              <div className={`row ${i % 2 === 0 ? 'light' : 'bold'} ${i === 0 ? 'first' : ''} ${!el?.right ? 'hidden' : ''}`}>{el?.right || '0'}</div>}
              </>
            )
        })}
    </div>
  </div>
  )
}
