import { configureStore } from '@reduxjs/toolkit'
import registration from "../reducers/registration"
import data from '../reducers/data'
import results from '../reducers/results'

export * from "../reducers/registration"

const store = configureStore({
    reducer: {
        registration,
        data,
        results
    },
})

// const createHistory = require('history').createBrowserHistory
// const history = createHistory()


export default store